// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles-module__tab__UMjLV{cursor:pointer;padding:0px 6px;margin-right:16px;white-space:nowrap}.styles-module__selected__Ga4K4{border-bottom:4px solid #0968c3}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/tabs/styles.module.scss"],"names":[],"mappings":"AAiDA,2BACE,cAAA,CACA,eAAA,CACA,iBAAA,CACA,kBAAA,CAGF,gCACE,+BAAA","sourcesContent":["@import '~oncokb-styles/scss/vars';\n\n$oncokb-font-font-size: 15px;\n$oncokb-blue: #0968c3;\n/* Overwrite the bootstrap defaults */\n// $font-family-sans-serif: 'Gotham Book', serif;\n// color overwrite\n$blue: $oncokb-blue;\n$grey: #808080; // grey\n$medium-grey: #c5c5c5;\n$light-grey: #f8f9fa; // light grey\n$primary: $oncokb-blue;\n$secondary: $grey;\n$info: #009d9a;\n$success: #28a745;\n$warning: #ffc107;\n$danger: #dc3545;\n$inactive: #f2f4f8; // from design team\n\n$link-hover-color: $oncokb-darker-blue;\n$nav-bg-color: $oncokb-blue;\n\n$navbar-padding-x: 0;\n$grid-breakpoints: (\n  xs: 0,\n  sm: 576px,\n  md: 768px,\n  lg: 1050px,\n  xl: 1500px,\n) !default;\n\n// we only need to define the max width for xl, smaller size will use the fluid width.\n$container-max-widths: (\n  xl: 1500px,\n) !default;\n\n$view-wrapper-default-p-y: 2rem;\n\n$bigger-default-font-size: 1.2rem;\n\n$breadcrumb-bg: $light-grey;\n\n$text-box-bg: #f1f9ff80;\n\n$somatic: $oncokb-blue;\n$germline: #f0f5ff;\n\n@import '../../variables.scss';\n\n.tab {\n  cursor: pointer;\n  padding: 0px 6px;\n  margin-right: 16px;\n  white-space: nowrap;\n}\n\n.selected {\n  border-bottom: 4px solid $oncokb-blue;\n}\n"],"sourceRoot":""}]);
// Exports
export var tab = `styles-module__tab__UMjLV`;
export var selected = `styles-module__selected__Ga4K4`;
export default ___CSS_LOADER_EXPORT___;
