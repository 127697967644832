/* tslint:disable */
/* eslint-disable */
/**
 * OncoKB Private APIs
 * These endpoints are for private use only.
 *
 * The version of the OpenAPI document: v1.4.1
 * Contact: contact@oncokb.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface ActionableGene
 */
export interface ActionableGene {
  /**
   *
   * @type {string}
   * @memberof ActionableGene
   */
  abstracts?: string;
  /**
   *
   * @type {string}
   * @memberof ActionableGene
   */
  cancerType?: string;
  /**
   *
   * @type {string}
   * @memberof ActionableGene
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ActionableGene
   */
  drugs?: string;
  /**
   *
   * @type {number}
   * @memberof ActionableGene
   */
  entrezGeneId?: number;
  /**
   *
   * @type {string}
   * @memberof ActionableGene
   */
  gene?: string;
  /**
   *
   * @type {string}
   * @memberof ActionableGene
   */
  grch37Isoform?: string;
  /**
   *
   * @type {string}
   * @memberof ActionableGene
   */
  grch37RefSeq?: string;
  /**
   *
   * @type {string}
   * @memberof ActionableGene
   */
  grch38Isoform?: string;
  /**
   *
   * @type {string}
   * @memberof ActionableGene
   */
  grch38RefSeq?: string;
  /**
   *
   * @type {string}
   * @memberof ActionableGene
   */
  level?: string;
  /**
   *
   * @type {string}
   * @memberof ActionableGene
   */
  pmids?: string;
  /**
   *
   * @type {string}
   * @memberof ActionableGene
   */
  proteinChange?: string;
  /**
   *
   * @type {string}
   * @memberof ActionableGene
   */
  referenceGenome?: string;
  /**
   *
   * @type {string}
   * @memberof ActionableGene
   */
  variant?: string;
}
/**
 *
 * @export
 * @interface Alteration
 */
export interface Alteration {
  /**
   *
   * @type {string}
   * @memberof Alteration
   */
  alteration?: string;
  /**
   *
   * @type {VariantConsequence}
   * @memberof Alteration
   */
  consequence?: VariantConsequence;
  /**
   *
   * @type {Gene}
   * @memberof Alteration
   */
  gene?: Gene;
  /**
   *
   * @type {string}
   * @memberof Alteration
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof Alteration
   */
  proteinEnd?: number;
  /**
   *
   * @type {number}
   * @memberof Alteration
   */
  proteinStart?: number;
  /**
   *
   * @type {string}
   * @memberof Alteration
   */
  refResidues?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Alteration
   */
  referenceGenomes?: Array<AlterationReferenceGenomesEnum>;
  /**
   *
   * @type {string}
   * @memberof Alteration
   */
  variantResidues?: string;
}

export const AlterationReferenceGenomesEnum = {
  Grch37: 'GRCh37',
  Grch38: 'GRCh38',
} as const;

export type AlterationReferenceGenomesEnum = typeof AlterationReferenceGenomesEnum[keyof typeof AlterationReferenceGenomesEnum];

/**
 *
 * @export
 * @interface AnnotateCopyNumberAlterationQuery
 */
export interface AnnotateCopyNumberAlterationQuery {
  /**
   *
   * @type {string}
   * @memberof AnnotateCopyNumberAlterationQuery
   */
  copyNameAlterationType?: AnnotateCopyNumberAlterationQueryCopyNameAlterationTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof AnnotateCopyNumberAlterationQuery
   */
  evidenceTypes?: Array<AnnotateCopyNumberAlterationQueryEvidenceTypesEnum>;
  /**
   *
   * @type {QueryGene}
   * @memberof AnnotateCopyNumberAlterationQuery
   */
  gene?: QueryGene;
  /**
   *
   * @type {string}
   * @memberof AnnotateCopyNumberAlterationQuery
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AnnotateCopyNumberAlterationQuery
   */
  referenceGenome?: AnnotateCopyNumberAlterationQueryReferenceGenomeEnum;
  /**
   *
   * @type {string}
   * @memberof AnnotateCopyNumberAlterationQuery
   */
  tumorType?: string;
}

export const AnnotateCopyNumberAlterationQueryCopyNameAlterationTypeEnum = {
  Amplification: 'AMPLIFICATION',
  Deletion: 'DELETION',
  Gain: 'GAIN',
  Loss: 'LOSS',
} as const;

export type AnnotateCopyNumberAlterationQueryCopyNameAlterationTypeEnum =
  typeof AnnotateCopyNumberAlterationQueryCopyNameAlterationTypeEnum[keyof typeof AnnotateCopyNumberAlterationQueryCopyNameAlterationTypeEnum];
export const AnnotateCopyNumberAlterationQueryEvidenceTypesEnum = {
  GeneSummary: 'GENE_SUMMARY',
  MutationSummary: 'MUTATION_SUMMARY',
  TumorTypeSummary: 'TUMOR_TYPE_SUMMARY',
  GeneTumorTypeSummary: 'GENE_TUMOR_TYPE_SUMMARY',
  PrognosticSummary: 'PROGNOSTIC_SUMMARY',
  DiagnosticSummary: 'DIAGNOSTIC_SUMMARY',
  GeneBackground: 'GENE_BACKGROUND',
  Oncogenic: 'ONCOGENIC',
  MutationEffect: 'MUTATION_EFFECT',
  Vus: 'VUS',
  PrognosticImplication: 'PROGNOSTIC_IMPLICATION',
  DiagnosticImplication: 'DIAGNOSTIC_IMPLICATION',
  StandardTherapeuticImplicationsForDrugSensitivity: 'STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY',
  StandardTherapeuticImplicationsForDrugResistance: 'STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE',
  InvestigationalTherapeuticImplicationsDrugSensitivity: 'INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY',
  InvestigationalTherapeuticImplicationsDrugResistance: 'INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE',
} as const;

export type AnnotateCopyNumberAlterationQueryEvidenceTypesEnum =
  typeof AnnotateCopyNumberAlterationQueryEvidenceTypesEnum[keyof typeof AnnotateCopyNumberAlterationQueryEvidenceTypesEnum];
export const AnnotateCopyNumberAlterationQueryReferenceGenomeEnum = {
  Grch37: 'GRCh37',
  Grch38: 'GRCh38',
} as const;

export type AnnotateCopyNumberAlterationQueryReferenceGenomeEnum =
  typeof AnnotateCopyNumberAlterationQueryReferenceGenomeEnum[keyof typeof AnnotateCopyNumberAlterationQueryReferenceGenomeEnum];

/**
 *
 * @export
 * @interface AnnotateMutationByGenomicChangeQuery
 */
export interface AnnotateMutationByGenomicChangeQuery {
  /**
   *
   * @type {Array<string>}
   * @memberof AnnotateMutationByGenomicChangeQuery
   */
  evidenceTypes?: Array<AnnotateMutationByGenomicChangeQueryEvidenceTypesEnum>;
  /**
   *
   * @type {string}
   * @memberof AnnotateMutationByGenomicChangeQuery
   */
  genomicLocation?: string;
  /**
   *
   * @type {string}
   * @memberof AnnotateMutationByGenomicChangeQuery
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AnnotateMutationByGenomicChangeQuery
   */
  referenceGenome?: AnnotateMutationByGenomicChangeQueryReferenceGenomeEnum;
  /**
   *
   * @type {string}
   * @memberof AnnotateMutationByGenomicChangeQuery
   */
  tumorType?: string;
}

export const AnnotateMutationByGenomicChangeQueryEvidenceTypesEnum = {
  GeneSummary: 'GENE_SUMMARY',
  MutationSummary: 'MUTATION_SUMMARY',
  TumorTypeSummary: 'TUMOR_TYPE_SUMMARY',
  GeneTumorTypeSummary: 'GENE_TUMOR_TYPE_SUMMARY',
  PrognosticSummary: 'PROGNOSTIC_SUMMARY',
  DiagnosticSummary: 'DIAGNOSTIC_SUMMARY',
  GeneBackground: 'GENE_BACKGROUND',
  Oncogenic: 'ONCOGENIC',
  MutationEffect: 'MUTATION_EFFECT',
  Vus: 'VUS',
  PrognosticImplication: 'PROGNOSTIC_IMPLICATION',
  DiagnosticImplication: 'DIAGNOSTIC_IMPLICATION',
  StandardTherapeuticImplicationsForDrugSensitivity: 'STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY',
  StandardTherapeuticImplicationsForDrugResistance: 'STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE',
  InvestigationalTherapeuticImplicationsDrugSensitivity: 'INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY',
  InvestigationalTherapeuticImplicationsDrugResistance: 'INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE',
} as const;

export type AnnotateMutationByGenomicChangeQueryEvidenceTypesEnum =
  typeof AnnotateMutationByGenomicChangeQueryEvidenceTypesEnum[keyof typeof AnnotateMutationByGenomicChangeQueryEvidenceTypesEnum];
export const AnnotateMutationByGenomicChangeQueryReferenceGenomeEnum = {
  Grch37: 'GRCh37',
  Grch38: 'GRCh38',
} as const;

export type AnnotateMutationByGenomicChangeQueryReferenceGenomeEnum =
  typeof AnnotateMutationByGenomicChangeQueryReferenceGenomeEnum[keyof typeof AnnotateMutationByGenomicChangeQueryReferenceGenomeEnum];

/**
 *
 * @export
 * @interface AnnotateMutationByHGVSgQuery
 */
export interface AnnotateMutationByHGVSgQuery {
  /**
   *
   * @type {Array<string>}
   * @memberof AnnotateMutationByHGVSgQuery
   */
  evidenceTypes?: Array<AnnotateMutationByHGVSgQueryEvidenceTypesEnum>;
  /**
   *
   * @type {string}
   * @memberof AnnotateMutationByHGVSgQuery
   */
  hgvsg?: string;
  /**
   *
   * @type {string}
   * @memberof AnnotateMutationByHGVSgQuery
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AnnotateMutationByHGVSgQuery
   */
  referenceGenome?: AnnotateMutationByHGVSgQueryReferenceGenomeEnum;
  /**
   *
   * @type {string}
   * @memberof AnnotateMutationByHGVSgQuery
   */
  tumorType?: string;
}

export const AnnotateMutationByHGVSgQueryEvidenceTypesEnum = {
  GeneSummary: 'GENE_SUMMARY',
  MutationSummary: 'MUTATION_SUMMARY',
  TumorTypeSummary: 'TUMOR_TYPE_SUMMARY',
  GeneTumorTypeSummary: 'GENE_TUMOR_TYPE_SUMMARY',
  PrognosticSummary: 'PROGNOSTIC_SUMMARY',
  DiagnosticSummary: 'DIAGNOSTIC_SUMMARY',
  GeneBackground: 'GENE_BACKGROUND',
  Oncogenic: 'ONCOGENIC',
  MutationEffect: 'MUTATION_EFFECT',
  Vus: 'VUS',
  PrognosticImplication: 'PROGNOSTIC_IMPLICATION',
  DiagnosticImplication: 'DIAGNOSTIC_IMPLICATION',
  StandardTherapeuticImplicationsForDrugSensitivity: 'STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY',
  StandardTherapeuticImplicationsForDrugResistance: 'STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE',
  InvestigationalTherapeuticImplicationsDrugSensitivity: 'INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY',
  InvestigationalTherapeuticImplicationsDrugResistance: 'INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE',
} as const;

export type AnnotateMutationByHGVSgQueryEvidenceTypesEnum =
  typeof AnnotateMutationByHGVSgQueryEvidenceTypesEnum[keyof typeof AnnotateMutationByHGVSgQueryEvidenceTypesEnum];
export const AnnotateMutationByHGVSgQueryReferenceGenomeEnum = {
  Grch37: 'GRCh37',
  Grch38: 'GRCh38',
} as const;

export type AnnotateMutationByHGVSgQueryReferenceGenomeEnum =
  typeof AnnotateMutationByHGVSgQueryReferenceGenomeEnum[keyof typeof AnnotateMutationByHGVSgQueryReferenceGenomeEnum];

/**
 *
 * @export
 * @interface AnnotateMutationByProteinChangeQuery
 */
export interface AnnotateMutationByProteinChangeQuery {
  /**
   *
   * @type {string}
   * @memberof AnnotateMutationByProteinChangeQuery
   */
  alteration?: string;
  /**
   *
   * @type {string}
   * @memberof AnnotateMutationByProteinChangeQuery
   */
  consequence?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof AnnotateMutationByProteinChangeQuery
   */
  evidenceTypes?: Array<AnnotateMutationByProteinChangeQueryEvidenceTypesEnum>;
  /**
   *
   * @type {QueryGene}
   * @memberof AnnotateMutationByProteinChangeQuery
   */
  gene?: QueryGene;
  /**
   *
   * @type {string}
   * @memberof AnnotateMutationByProteinChangeQuery
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof AnnotateMutationByProteinChangeQuery
   */
  proteinEnd?: number;
  /**
   *
   * @type {number}
   * @memberof AnnotateMutationByProteinChangeQuery
   */
  proteinStart?: number;
  /**
   *
   * @type {string}
   * @memberof AnnotateMutationByProteinChangeQuery
   */
  referenceGenome?: AnnotateMutationByProteinChangeQueryReferenceGenomeEnum;
  /**
   *
   * @type {string}
   * @memberof AnnotateMutationByProteinChangeQuery
   */
  tumorType?: string;
}

export const AnnotateMutationByProteinChangeQueryEvidenceTypesEnum = {
  GeneSummary: 'GENE_SUMMARY',
  MutationSummary: 'MUTATION_SUMMARY',
  TumorTypeSummary: 'TUMOR_TYPE_SUMMARY',
  GeneTumorTypeSummary: 'GENE_TUMOR_TYPE_SUMMARY',
  PrognosticSummary: 'PROGNOSTIC_SUMMARY',
  DiagnosticSummary: 'DIAGNOSTIC_SUMMARY',
  GeneBackground: 'GENE_BACKGROUND',
  Oncogenic: 'ONCOGENIC',
  MutationEffect: 'MUTATION_EFFECT',
  Vus: 'VUS',
  PrognosticImplication: 'PROGNOSTIC_IMPLICATION',
  DiagnosticImplication: 'DIAGNOSTIC_IMPLICATION',
  StandardTherapeuticImplicationsForDrugSensitivity: 'STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY',
  StandardTherapeuticImplicationsForDrugResistance: 'STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE',
  InvestigationalTherapeuticImplicationsDrugSensitivity: 'INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY',
  InvestigationalTherapeuticImplicationsDrugResistance: 'INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE',
} as const;

export type AnnotateMutationByProteinChangeQueryEvidenceTypesEnum =
  typeof AnnotateMutationByProteinChangeQueryEvidenceTypesEnum[keyof typeof AnnotateMutationByProteinChangeQueryEvidenceTypesEnum];
export const AnnotateMutationByProteinChangeQueryReferenceGenomeEnum = {
  Grch37: 'GRCh37',
  Grch38: 'GRCh38',
} as const;

export type AnnotateMutationByProteinChangeQueryReferenceGenomeEnum =
  typeof AnnotateMutationByProteinChangeQueryReferenceGenomeEnum[keyof typeof AnnotateMutationByProteinChangeQueryReferenceGenomeEnum];

/**
 *
 * @export
 * @interface AnnotateStructuralVariantQuery
 */
export interface AnnotateStructuralVariantQuery {
  /**
   *
   * @type {Array<string>}
   * @memberof AnnotateStructuralVariantQuery
   */
  evidenceTypes?: Array<AnnotateStructuralVariantQueryEvidenceTypesEnum>;
  /**
   *
   * @type {boolean}
   * @memberof AnnotateStructuralVariantQuery
   */
  functionalFusion?: boolean;
  /**
   *
   * @type {QueryGene}
   * @memberof AnnotateStructuralVariantQuery
   */
  geneA?: QueryGene;
  /**
   *
   * @type {QueryGene}
   * @memberof AnnotateStructuralVariantQuery
   */
  geneB?: QueryGene;
  /**
   *
   * @type {string}
   * @memberof AnnotateStructuralVariantQuery
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AnnotateStructuralVariantQuery
   */
  referenceGenome?: AnnotateStructuralVariantQueryReferenceGenomeEnum;
  /**
   *
   * @type {string}
   * @memberof AnnotateStructuralVariantQuery
   */
  structuralVariantType?: AnnotateStructuralVariantQueryStructuralVariantTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AnnotateStructuralVariantQuery
   */
  tumorType?: string;
}

export const AnnotateStructuralVariantQueryEvidenceTypesEnum = {
  GeneSummary: 'GENE_SUMMARY',
  MutationSummary: 'MUTATION_SUMMARY',
  TumorTypeSummary: 'TUMOR_TYPE_SUMMARY',
  GeneTumorTypeSummary: 'GENE_TUMOR_TYPE_SUMMARY',
  PrognosticSummary: 'PROGNOSTIC_SUMMARY',
  DiagnosticSummary: 'DIAGNOSTIC_SUMMARY',
  GeneBackground: 'GENE_BACKGROUND',
  Oncogenic: 'ONCOGENIC',
  MutationEffect: 'MUTATION_EFFECT',
  Vus: 'VUS',
  PrognosticImplication: 'PROGNOSTIC_IMPLICATION',
  DiagnosticImplication: 'DIAGNOSTIC_IMPLICATION',
  StandardTherapeuticImplicationsForDrugSensitivity: 'STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY',
  StandardTherapeuticImplicationsForDrugResistance: 'STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE',
  InvestigationalTherapeuticImplicationsDrugSensitivity: 'INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY',
  InvestigationalTherapeuticImplicationsDrugResistance: 'INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE',
} as const;

export type AnnotateStructuralVariantQueryEvidenceTypesEnum =
  typeof AnnotateStructuralVariantQueryEvidenceTypesEnum[keyof typeof AnnotateStructuralVariantQueryEvidenceTypesEnum];
export const AnnotateStructuralVariantQueryReferenceGenomeEnum = {
  Grch37: 'GRCh37',
  Grch38: 'GRCh38',
} as const;

export type AnnotateStructuralVariantQueryReferenceGenomeEnum =
  typeof AnnotateStructuralVariantQueryReferenceGenomeEnum[keyof typeof AnnotateStructuralVariantQueryReferenceGenomeEnum];
export const AnnotateStructuralVariantQueryStructuralVariantTypeEnum = {
  Deletion: 'DELETION',
  Translocation: 'TRANSLOCATION',
  Duplication: 'DUPLICATION',
  Insertion: 'INSERTION',
  Inversion: 'INVERSION',
  Fusion: 'FUSION',
  Unknown: 'UNKNOWN',
} as const;

export type AnnotateStructuralVariantQueryStructuralVariantTypeEnum =
  typeof AnnotateStructuralVariantQueryStructuralVariantTypeEnum[keyof typeof AnnotateStructuralVariantQueryStructuralVariantTypeEnum];

/**
 *
 * @export
 * @interface AnnotatedVariant
 */
export interface AnnotatedVariant {
  /**
   *
   * @type {string}
   * @memberof AnnotatedVariant
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof AnnotatedVariant
   */
  entrezGeneId?: number;
  /**
   *
   * @type {string}
   * @memberof AnnotatedVariant
   */
  gene?: string;
  /**
   *
   * @type {string}
   * @memberof AnnotatedVariant
   */
  grch37Isoform?: string;
  /**
   *
   * @type {string}
   * @memberof AnnotatedVariant
   */
  grch37RefSeq?: string;
  /**
   *
   * @type {string}
   * @memberof AnnotatedVariant
   */
  grch38Isoform?: string;
  /**
   *
   * @type {string}
   * @memberof AnnotatedVariant
   */
  grch38RefSeq?: string;
  /**
   *
   * @type {string}
   * @memberof AnnotatedVariant
   */
  mutationEffect?: string;
  /**
   *
   * @type {string}
   * @memberof AnnotatedVariant
   */
  mutationEffectAbstracts?: string;
  /**
   *
   * @type {string}
   * @memberof AnnotatedVariant
   */
  mutationEffectPmids?: string;
  /**
   *
   * @type {string}
   * @memberof AnnotatedVariant
   */
  oncogenicity?: string;
  /**
   *
   * @type {string}
   * @memberof AnnotatedVariant
   */
  proteinChange?: string;
  /**
   *
   * @type {string}
   * @memberof AnnotatedVariant
   */
  referenceGenome?: string;
  /**
   *
   * @type {string}
   * @memberof AnnotatedVariant
   */
  variant?: string;
}
/**
 *
 * @export
 * @interface AnnotationSearchResult
 */
export interface AnnotationSearchResult {
  /**
   *
   * @type {IndicatorQueryResp}
   * @memberof AnnotationSearchResult
   */
  indicatorQueryResp?: IndicatorQueryResp;
  /**
   *
   * @type {string}
   * @memberof AnnotationSearchResult
   */
  queryType?: AnnotationSearchResultQueryTypeEnum;
}

export const AnnotationSearchResultQueryTypeEnum = {
  Gene: 'GENE',
  Variant: 'VARIANT',
  Drug: 'DRUG',
  CancerType: 'CANCER_TYPE',
} as const;

export type AnnotationSearchResultQueryTypeEnum =
  typeof AnnotationSearchResultQueryTypeEnum[keyof typeof AnnotationSearchResultQueryTypeEnum];

/**
 *
 * @export
 * @interface Arms
 */
export interface Arms {
  /**
   *
   * @type {string}
   * @memberof Arms
   */
  armDescription?: string;
  /**
   *
   * @type {Array<Drug>}
   * @memberof Arms
   */
  drugs?: Array<Drug>;
}
/**
 *
 * @export
 * @interface Article
 */
export interface Article {
  /**
   *
   * @type {string}
   * @memberof Article
   */
  abstract?: string;
  /**
   *
   * @type {string}
   * @memberof Article
   */
  authors?: string;
  /**
   *
   * @type {string}
   * @memberof Article
   */
  elocationId?: string;
  /**
   *
   * @type {string}
   * @memberof Article
   */
  issue?: string;
  /**
   *
   * @type {string}
   * @memberof Article
   */
  journal?: string;
  /**
   *
   * @type {string}
   * @memberof Article
   */
  link?: string;
  /**
   *
   * @type {string}
   * @memberof Article
   */
  pages?: string;
  /**
   *
   * @type {string}
   * @memberof Article
   */
  pmid?: string;
  /**
   *
   * @type {string}
   * @memberof Article
   */
  pubDate?: string;
  /**
   *
   * @type {string}
   * @memberof Article
   */
  reference?: string;
  /**
   *
   * @type {string}
   * @memberof Article
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof Article
   */
  volume?: string;
}
/**
 *
 * @export
 * @interface ArticleAbstract
 */
export interface ArticleAbstract {
  /**
   *
   * @type {string}
   * @memberof ArticleAbstract
   */
  abstract?: string;
  /**
   *
   * @type {string}
   * @memberof ArticleAbstract
   */
  link?: string;
}
/**
 *
 * @export
 * @interface CancerGene
 */
export interface CancerGene {
  /**
   *
   * @type {number}
   * @memberof CancerGene
   */
  entrezGeneId?: number;
  /**
   *
   * @type {boolean}
   * @memberof CancerGene
   */
  foundation?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CancerGene
   */
  foundationHeme?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof CancerGene
   */
  geneAliases?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CancerGene
   */
  grch37Isoform?: string;
  /**
   *
   * @type {string}
   * @memberof CancerGene
   */
  grch37RefSeq?: string;
  /**
   *
   * @type {string}
   * @memberof CancerGene
   */
  grch38Isoform?: string;
  /**
   *
   * @type {string}
   * @memberof CancerGene
   */
  grch38RefSeq?: string;
  /**
   *
   * @type {string}
   * @memberof CancerGene
   */
  hugoSymbol?: string;
  /**
   *
   * @type {boolean}
   * @memberof CancerGene
   */
  mSKHeme?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CancerGene
   */
  mSKImpact?: boolean;
  /**
   *
   * @type {number}
   * @memberof CancerGene
   */
  occurrenceCount?: number;
  /**
   *
   * @type {boolean}
   * @memberof CancerGene
   */
  oncogene?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CancerGene
   */
  oncokbAnnotated?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CancerGene
   */
  sangerCGC?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CancerGene
   */
  tsg?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CancerGene
   */
  vogelstein?: boolean;
}
/**
 *
 * @export
 * @interface CancerTypesQuery
 */
export interface CancerTypesQuery {
  /**
   *
   * @type {Array<string>}
   * @memberof CancerTypesQuery
   */
  cancerTypes?: Array<string>;
}
/**
 *
 * @export
 * @interface Citations
 */
export interface Citations {
  /**
   *
   * @type {Array<ArticleAbstract>}
   * @memberof Citations
   */
  abstracts?: Array<ArticleAbstract>;
  /**
   *
   * @type {Array<string>}
   * @memberof Citations
   */
  pmids?: Array<string>;
}
/**
 *
 * @export
 * @interface CuratedGene
 */
export interface CuratedGene {
  /**
   *
   * @type {string}
   * @memberof CuratedGene
   */
  background?: string;
  /**
   *
   * @type {number}
   * @memberof CuratedGene
   */
  entrezGeneId?: number;
  /**
   *
   * @type {string}
   * @memberof CuratedGene
   */
  grch37Isoform?: string;
  /**
   *
   * @type {string}
   * @memberof CuratedGene
   */
  grch37RefSeq?: string;
  /**
   *
   * @type {string}
   * @memberof CuratedGene
   */
  grch38Isoform?: string;
  /**
   *
   * @type {string}
   * @memberof CuratedGene
   */
  grch38RefSeq?: string;
  /**
   *
   * @type {string}
   * @memberof CuratedGene
   */
  highestResistancLevel?: string;
  /**
   *
   * @type {string}
   * @memberof CuratedGene
   */
  highestResistanceLevel?: string;
  /**
   *
   * @type {string}
   * @memberof CuratedGene
   */
  highestSensitiveLevel?: string;
  /**
   *
   * @type {string}
   * @memberof CuratedGene
   */
  hugoSymbol?: string;
  /**
   *
   * @type {boolean}
   * @memberof CuratedGene
   */
  oncogene?: boolean;
  /**
   *
   * @type {string}
   * @memberof CuratedGene
   */
  summary?: string;
  /**
   *
   * @type {boolean}
   * @memberof CuratedGene
   */
  tsg?: boolean;
}
/**
 *
 * @export
 * @interface Drug
 */
export interface Drug {
  /**
   *
   * @type {string}
   * @memberof Drug
   */
  drugName?: string;
  /**
   *
   * @type {string}
   * @memberof Drug
   */
  ncitCode?: string;
}
/**
 *
 * @export
 * @interface Evidence
 */
export interface Evidence {
  /**
   *
   * @type {string}
   * @memberof Evidence
   */
  additionalInfo?: string;
  /**
   *
   * @type {Array<Alteration>}
   * @memberof Evidence
   */
  alterations?: Array<Alteration>;
  /**
   *
   * @type {Array<Article>}
   * @memberof Evidence
   */
  articles?: Array<Article>;
  /**
   *
   * @type {Array<TumorType>}
   * @memberof Evidence
   */
  cancerTypes?: Array<TumorType>;
  /**
   *
   * @type {string}
   * @memberof Evidence
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof Evidence
   */
  evidenceType?: EvidenceEvidenceTypeEnum;
  /**
   *
   * @type {Array<TumorType>}
   * @memberof Evidence
   */
  excludedCancerTypes?: Array<TumorType>;
  /**
   *
   * @type {string}
   * @memberof Evidence
   */
  fdaLevel?: EvidenceFdaLevelEnum;
  /**
   *
   * @type {Gene}
   * @memberof Evidence
   */
  gene?: Gene;
  /**
   *
   * @type {number}
   * @memberof Evidence
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Evidence
   */
  knownEffect?: string;
  /**
   *
   * @type {string}
   * @memberof Evidence
   */
  lastEdit?: string;
  /**
   *
   * @type {string}
   * @memberof Evidence
   */
  lastReview?: string;
  /**
   *
   * @type {string}
   * @memberof Evidence
   */
  levelOfEvidence?: EvidenceLevelOfEvidenceEnum;
  /**
   *
   * @type {string}
   * @memberof Evidence
   */
  liquidPropagationLevel?: EvidenceLiquidPropagationLevelEnum;
  /**
   *
   * @type {Array<TumorType>}
   * @memberof Evidence
   */
  relevantCancerTypes?: Array<TumorType>;
  /**
   *
   * @type {string}
   * @memberof Evidence
   */
  solidPropagationLevel?: EvidenceSolidPropagationLevelEnum;
  /**
   *
   * @type {Array<Treatment>}
   * @memberof Evidence
   */
  treatments?: Array<Treatment>;
  /**
   *
   * @type {string}
   * @memberof Evidence
   */
  uuid?: string;
}

export const EvidenceEvidenceTypeEnum = {
  GeneSummary: 'GENE_SUMMARY',
  MutationSummary: 'MUTATION_SUMMARY',
  TumorTypeSummary: 'TUMOR_TYPE_SUMMARY',
  GeneTumorTypeSummary: 'GENE_TUMOR_TYPE_SUMMARY',
  PrognosticSummary: 'PROGNOSTIC_SUMMARY',
  DiagnosticSummary: 'DIAGNOSTIC_SUMMARY',
  GeneBackground: 'GENE_BACKGROUND',
  Oncogenic: 'ONCOGENIC',
  MutationEffect: 'MUTATION_EFFECT',
  Vus: 'VUS',
  PrognosticImplication: 'PROGNOSTIC_IMPLICATION',
  DiagnosticImplication: 'DIAGNOSTIC_IMPLICATION',
  StandardTherapeuticImplicationsForDrugSensitivity: 'STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY',
  StandardTherapeuticImplicationsForDrugResistance: 'STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE',
  InvestigationalTherapeuticImplicationsDrugSensitivity: 'INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY',
  InvestigationalTherapeuticImplicationsDrugResistance: 'INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE',
} as const;

export type EvidenceEvidenceTypeEnum = typeof EvidenceEvidenceTypeEnum[keyof typeof EvidenceEvidenceTypeEnum];
export const EvidenceFdaLevelEnum = {
  Level1: 'LEVEL_1',
  Level2: 'LEVEL_2',
  Level3A: 'LEVEL_3A',
  Level3B: 'LEVEL_3B',
  Level4: 'LEVEL_4',
  LevelR1: 'LEVEL_R1',
  LevelR2: 'LEVEL_R2',
  LevelPx1: 'LEVEL_Px1',
  LevelPx2: 'LEVEL_Px2',
  LevelPx3: 'LEVEL_Px3',
  LevelDx1: 'LEVEL_Dx1',
  LevelDx2: 'LEVEL_Dx2',
  LevelDx3: 'LEVEL_Dx3',
  LevelFda1: 'LEVEL_Fda1',
  LevelFda2: 'LEVEL_Fda2',
  LevelFda3: 'LEVEL_Fda3',
  No: 'NO',
} as const;

export type EvidenceFdaLevelEnum = typeof EvidenceFdaLevelEnum[keyof typeof EvidenceFdaLevelEnum];
export const EvidenceLevelOfEvidenceEnum = {
  Level1: 'LEVEL_1',
  Level2: 'LEVEL_2',
  Level3A: 'LEVEL_3A',
  Level3B: 'LEVEL_3B',
  Level4: 'LEVEL_4',
  LevelR1: 'LEVEL_R1',
  LevelR2: 'LEVEL_R2',
  LevelPx1: 'LEVEL_Px1',
  LevelPx2: 'LEVEL_Px2',
  LevelPx3: 'LEVEL_Px3',
  LevelDx1: 'LEVEL_Dx1',
  LevelDx2: 'LEVEL_Dx2',
  LevelDx3: 'LEVEL_Dx3',
  LevelFda1: 'LEVEL_Fda1',
  LevelFda2: 'LEVEL_Fda2',
  LevelFda3: 'LEVEL_Fda3',
  No: 'NO',
} as const;

export type EvidenceLevelOfEvidenceEnum = typeof EvidenceLevelOfEvidenceEnum[keyof typeof EvidenceLevelOfEvidenceEnum];
export const EvidenceLiquidPropagationLevelEnum = {
  Level1: 'LEVEL_1',
  Level2: 'LEVEL_2',
  Level3A: 'LEVEL_3A',
  Level3B: 'LEVEL_3B',
  Level4: 'LEVEL_4',
  LevelR1: 'LEVEL_R1',
  LevelR2: 'LEVEL_R2',
  LevelPx1: 'LEVEL_Px1',
  LevelPx2: 'LEVEL_Px2',
  LevelPx3: 'LEVEL_Px3',
  LevelDx1: 'LEVEL_Dx1',
  LevelDx2: 'LEVEL_Dx2',
  LevelDx3: 'LEVEL_Dx3',
  LevelFda1: 'LEVEL_Fda1',
  LevelFda2: 'LEVEL_Fda2',
  LevelFda3: 'LEVEL_Fda3',
  No: 'NO',
} as const;

export type EvidenceLiquidPropagationLevelEnum = typeof EvidenceLiquidPropagationLevelEnum[keyof typeof EvidenceLiquidPropagationLevelEnum];
export const EvidenceSolidPropagationLevelEnum = {
  Level1: 'LEVEL_1',
  Level2: 'LEVEL_2',
  Level3A: 'LEVEL_3A',
  Level3B: 'LEVEL_3B',
  Level4: 'LEVEL_4',
  LevelR1: 'LEVEL_R1',
  LevelR2: 'LEVEL_R2',
  LevelPx1: 'LEVEL_Px1',
  LevelPx2: 'LEVEL_Px2',
  LevelPx3: 'LEVEL_Px3',
  LevelDx1: 'LEVEL_Dx1',
  LevelDx2: 'LEVEL_Dx2',
  LevelDx3: 'LEVEL_Dx3',
  LevelFda1: 'LEVEL_Fda1',
  LevelFda2: 'LEVEL_Fda2',
  LevelFda3: 'LEVEL_Fda3',
  No: 'NO',
} as const;

export type EvidenceSolidPropagationLevelEnum = typeof EvidenceSolidPropagationLevelEnum[keyof typeof EvidenceSolidPropagationLevelEnum];

/**
 *
 * @export
 * @interface EvidenceQueries
 */
export interface EvidenceQueries {
  /**
   *
   * @type {string}
   * @memberof EvidenceQueries
   */
  evidenceTypes?: string;
  /**
   *
   * @type {boolean}
   * @memberof EvidenceQueries
   */
  highestLevelOnly?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof EvidenceQueries
   */
  levels?: Array<EvidenceQueriesLevelsEnum>;
  /**
   *
   * @type {Array<Query>}
   * @memberof EvidenceQueries
   */
  queries?: Array<Query>;
}

export const EvidenceQueriesLevelsEnum = {
  Level1: 'LEVEL_1',
  Level2: 'LEVEL_2',
  Level3A: 'LEVEL_3A',
  Level3B: 'LEVEL_3B',
  Level4: 'LEVEL_4',
  LevelR1: 'LEVEL_R1',
  LevelR2: 'LEVEL_R2',
  LevelPx1: 'LEVEL_Px1',
  LevelPx2: 'LEVEL_Px2',
  LevelPx3: 'LEVEL_Px3',
  LevelDx1: 'LEVEL_Dx1',
  LevelDx2: 'LEVEL_Dx2',
  LevelDx3: 'LEVEL_Dx3',
  LevelFda1: 'LEVEL_Fda1',
  LevelFda2: 'LEVEL_Fda2',
  LevelFda3: 'LEVEL_Fda3',
  No: 'NO',
} as const;

export type EvidenceQueriesLevelsEnum = typeof EvidenceQueriesLevelsEnum[keyof typeof EvidenceQueriesLevelsEnum];

/**
 *
 * @export
 * @interface EvidenceQueryRes
 */
export interface EvidenceQueryRes {
  /**
   *
   * @type {Array<Alteration>}
   * @memberof EvidenceQueryRes
   */
  alleles?: Array<Alteration>;
  /**
   *
   * @type {Array<Alteration>}
   * @memberof EvidenceQueryRes
   */
  alterations?: Array<Alteration>;
  /**
   *
   * @type {Array<Evidence>}
   * @memberof EvidenceQueryRes
   */
  evidences?: Array<Evidence>;
  /**
   *
   * @type {Alteration}
   * @memberof EvidenceQueryRes
   */
  exactMatchedAlteration?: Alteration;
  /**
   *
   * @type {TumorType}
   * @memberof EvidenceQueryRes
   */
  exactMatchedTumorType?: TumorType;
  /**
   *
   * @type {Gene}
   * @memberof EvidenceQueryRes
   */
  gene?: Gene;
  /**
   *
   * @type {string}
   * @memberof EvidenceQueryRes
   */
  id?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof EvidenceQueryRes
   */
  levelOfEvidences?: Array<EvidenceQueryResLevelOfEvidencesEnum>;
  /**
   *
   * @type {Array<TumorType>}
   * @memberof EvidenceQueryRes
   */
  oncoTreeTypes?: Array<TumorType>;
  /**
   *
   * @type {Query}
   * @memberof EvidenceQueryRes
   */
  query?: Query;
}

export const EvidenceQueryResLevelOfEvidencesEnum = {
  Level1: 'LEVEL_1',
  Level2: 'LEVEL_2',
  Level3A: 'LEVEL_3A',
  Level3B: 'LEVEL_3B',
  Level4: 'LEVEL_4',
  LevelR1: 'LEVEL_R1',
  LevelR2: 'LEVEL_R2',
  LevelPx1: 'LEVEL_Px1',
  LevelPx2: 'LEVEL_Px2',
  LevelPx3: 'LEVEL_Px3',
  LevelDx1: 'LEVEL_Dx1',
  LevelDx2: 'LEVEL_Dx2',
  LevelDx3: 'LEVEL_Dx3',
  LevelFda1: 'LEVEL_Fda1',
  LevelFda2: 'LEVEL_Fda2',
  LevelFda3: 'LEVEL_Fda3',
  No: 'NO',
} as const;

export type EvidenceQueryResLevelOfEvidencesEnum =
  typeof EvidenceQueryResLevelOfEvidencesEnum[keyof typeof EvidenceQueryResLevelOfEvidencesEnum];

/**
 *
 * @export
 * @interface Gene
 */
export interface Gene {
  /**
   *
   * @type {number}
   * @memberof Gene
   */
  entrezGeneId?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof Gene
   */
  geneAliases?: Array<string>;
  /**
   *
   * @type {Array<Geneset>}
   * @memberof Gene
   */
  genesets?: Array<Geneset>;
  /**
   *
   * @type {string}
   * @memberof Gene
   */
  grch37Isoform?: string;
  /**
   *
   * @type {string}
   * @memberof Gene
   */
  grch37RefSeq?: string;
  /**
   *
   * @type {string}
   * @memberof Gene
   */
  grch38Isoform?: string;
  /**
   *
   * @type {string}
   * @memberof Gene
   */
  grch38RefSeq?: string;
  /**
   *
   * @type {string}
   * @memberof Gene
   */
  hugoSymbol?: string;
  /**
   *
   * @type {boolean}
   * @memberof Gene
   */
  oncogene?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Gene
   */
  tsg?: boolean;
}
/**
 *
 * @export
 * @interface GeneEvidence
 */
export interface GeneEvidence {
  /**
   *
   * @type {Array<Article>}
   * @memberof GeneEvidence
   */
  articles?: Array<Article>;
  /**
   *
   * @type {string}
   * @memberof GeneEvidence
   */
  desc?: string;
  /**
   *
   * @type {number}
   * @memberof GeneEvidence
   */
  evidenceId: number;
  /**
   *
   * @type {string}
   * @memberof GeneEvidence
   */
  evidenceType: GeneEvidenceEvidenceTypeEnum;
  /**
   *
   * @type {Gene}
   * @memberof GeneEvidence
   */
  gene?: Gene;
  /**
   *
   * @type {string}
   * @memberof GeneEvidence
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof GeneEvidence
   */
  lastEdit?: string;
  /**
   *
   * @type {string}
   * @memberof GeneEvidence
   */
  shortDesc?: string;
  /**
   *
   * @type {string}
   * @memberof GeneEvidence
   */
  status?: string;
}

export const GeneEvidenceEvidenceTypeEnum = {
  GeneSummary: 'GENE_SUMMARY',
  MutationSummary: 'MUTATION_SUMMARY',
  TumorTypeSummary: 'TUMOR_TYPE_SUMMARY',
  GeneTumorTypeSummary: 'GENE_TUMOR_TYPE_SUMMARY',
  PrognosticSummary: 'PROGNOSTIC_SUMMARY',
  DiagnosticSummary: 'DIAGNOSTIC_SUMMARY',
  GeneBackground: 'GENE_BACKGROUND',
  Oncogenic: 'ONCOGENIC',
  MutationEffect: 'MUTATION_EFFECT',
  Vus: 'VUS',
  PrognosticImplication: 'PROGNOSTIC_IMPLICATION',
  DiagnosticImplication: 'DIAGNOSTIC_IMPLICATION',
  StandardTherapeuticImplicationsForDrugSensitivity: 'STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY',
  StandardTherapeuticImplicationsForDrugResistance: 'STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE',
  InvestigationalTherapeuticImplicationsDrugSensitivity: 'INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY',
  InvestigationalTherapeuticImplicationsDrugResistance: 'INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE',
} as const;

export type GeneEvidenceEvidenceTypeEnum = typeof GeneEvidenceEvidenceTypeEnum[keyof typeof GeneEvidenceEvidenceTypeEnum];

/**
 *
 * @export
 * @interface Geneset
 */
export interface Geneset {
  /**
   *
   * @type {Array<Gene>}
   * @memberof Geneset
   */
  genes?: Array<Gene>;
  /**
   *
   * @type {number}
   * @memberof Geneset
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Geneset
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Geneset
   */
  uuid?: string;
}
/**
 *
 * @export
 * @interface Implication
 */
export interface Implication {
  /**
   *
   * @type {Array<ArticleAbstract>}
   * @memberof Implication
   */
  abstracts?: Array<ArticleAbstract>;
  /**
   *
   * @type {Array<string>}
   * @memberof Implication
   */
  alterations?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Implication
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof Implication
   */
  levelOfEvidence?: ImplicationLevelOfEvidenceEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof Implication
   */
  pmids?: Array<string>;
  /**
   *
   * @type {TumorType}
   * @memberof Implication
   */
  tumorType?: TumorType;
}

export const ImplicationLevelOfEvidenceEnum = {
  Level1: 'LEVEL_1',
  Level2: 'LEVEL_2',
  Level3A: 'LEVEL_3A',
  Level3B: 'LEVEL_3B',
  Level4: 'LEVEL_4',
  LevelR1: 'LEVEL_R1',
  LevelR2: 'LEVEL_R2',
  LevelPx1: 'LEVEL_Px1',
  LevelPx2: 'LEVEL_Px2',
  LevelPx3: 'LEVEL_Px3',
  LevelDx1: 'LEVEL_Dx1',
  LevelDx2: 'LEVEL_Dx2',
  LevelDx3: 'LEVEL_Dx3',
  LevelFda1: 'LEVEL_Fda1',
  LevelFda2: 'LEVEL_Fda2',
  LevelFda3: 'LEVEL_Fda3',
  No: 'NO',
} as const;

export type ImplicationLevelOfEvidenceEnum = typeof ImplicationLevelOfEvidenceEnum[keyof typeof ImplicationLevelOfEvidenceEnum];

/**
 *
 * @export
 * @interface IndicatorQueryResp
 */
export interface IndicatorQueryResp {
  /**
   *
   * @type {boolean}
   * @memberof IndicatorQueryResp
   */
  alleleExist?: boolean;
  /**
   *
   * @type {string}
   * @memberof IndicatorQueryResp
   */
  dataVersion?: string;
  /**
   *
   * @type {Array<Implication>}
   * @memberof IndicatorQueryResp
   */
  diagnosticImplications?: Array<Implication>;
  /**
   *
   * @type {string}
   * @memberof IndicatorQueryResp
   */
  diagnosticSummary?: string;
  /**
   *
   * @type {boolean}
   * @memberof IndicatorQueryResp
   */
  geneExist?: boolean;
  /**
   *
   * @type {string}
   * @memberof IndicatorQueryResp
   */
  geneSummary?: string;
  /**
   *
   * @type {string}
   * @memberof IndicatorQueryResp
   */
  highestDiagnosticImplicationLevel?: IndicatorQueryRespHighestDiagnosticImplicationLevelEnum;
  /**
   *
   * @type {string}
   * @memberof IndicatorQueryResp
   */
  highestFdaLevel?: IndicatorQueryRespHighestFdaLevelEnum;
  /**
   *
   * @type {string}
   * @memberof IndicatorQueryResp
   */
  highestPrognosticImplicationLevel?: IndicatorQueryRespHighestPrognosticImplicationLevelEnum;
  /**
   *
   * @type {string}
   * @memberof IndicatorQueryResp
   */
  highestResistanceLevel?: IndicatorQueryRespHighestResistanceLevelEnum;
  /**
   *
   * @type {string}
   * @memberof IndicatorQueryResp
   */
  highestSensitiveLevel?: IndicatorQueryRespHighestSensitiveLevelEnum;
  /**
   *
   * @type {boolean}
   * @memberof IndicatorQueryResp
   */
  hotspot?: boolean;
  /**
   *
   * @type {string}
   * @memberof IndicatorQueryResp
   */
  lastUpdate?: string;
  /**
   *
   * @type {MutationEffectResp}
   * @memberof IndicatorQueryResp
   */
  mutationEffect?: MutationEffectResp;
  /**
   *
   * @type {string}
   * @memberof IndicatorQueryResp
   */
  oncogenic?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof IndicatorQueryResp
   */
  otherSignificantResistanceLevels?: Array<IndicatorQueryRespOtherSignificantResistanceLevelsEnum>;
  /**
   *
   * @type {Array<string>}
   * @memberof IndicatorQueryResp
   */
  otherSignificantSensitiveLevels?: Array<IndicatorQueryRespOtherSignificantSensitiveLevelsEnum>;
  /**
   *
   * @type {Array<Implication>}
   * @memberof IndicatorQueryResp
   */
  prognosticImplications?: Array<Implication>;
  /**
   *
   * @type {string}
   * @memberof IndicatorQueryResp
   */
  prognosticSummary?: string;
  /**
   *
   * @type {Query}
   * @memberof IndicatorQueryResp
   */
  query?: Query;
  /**
   *
   * @type {Array<IndicatorQueryTreatment>}
   * @memberof IndicatorQueryResp
   */
  treatments?: Array<IndicatorQueryTreatment>;
  /**
   *
   * @type {string}
   * @memberof IndicatorQueryResp
   */
  tumorTypeSummary?: string;
  /**
   *
   * @type {boolean}
   * @memberof IndicatorQueryResp
   */
  variantExist?: boolean;
  /**
   *
   * @type {string}
   * @memberof IndicatorQueryResp
   */
  variantSummary?: string;
  /**
   *
   * @type {boolean}
   * @memberof IndicatorQueryResp
   */
  vus?: boolean;
}

export const IndicatorQueryRespHighestDiagnosticImplicationLevelEnum = {
  Level1: 'LEVEL_1',
  Level2: 'LEVEL_2',
  Level3A: 'LEVEL_3A',
  Level3B: 'LEVEL_3B',
  Level4: 'LEVEL_4',
  LevelR1: 'LEVEL_R1',
  LevelR2: 'LEVEL_R2',
  LevelPx1: 'LEVEL_Px1',
  LevelPx2: 'LEVEL_Px2',
  LevelPx3: 'LEVEL_Px3',
  LevelDx1: 'LEVEL_Dx1',
  LevelDx2: 'LEVEL_Dx2',
  LevelDx3: 'LEVEL_Dx3',
  LevelFda1: 'LEVEL_Fda1',
  LevelFda2: 'LEVEL_Fda2',
  LevelFda3: 'LEVEL_Fda3',
  No: 'NO',
} as const;

export type IndicatorQueryRespHighestDiagnosticImplicationLevelEnum =
  typeof IndicatorQueryRespHighestDiagnosticImplicationLevelEnum[keyof typeof IndicatorQueryRespHighestDiagnosticImplicationLevelEnum];
export const IndicatorQueryRespHighestFdaLevelEnum = {
  Level1: 'LEVEL_1',
  Level2: 'LEVEL_2',
  Level3A: 'LEVEL_3A',
  Level3B: 'LEVEL_3B',
  Level4: 'LEVEL_4',
  LevelR1: 'LEVEL_R1',
  LevelR2: 'LEVEL_R2',
  LevelPx1: 'LEVEL_Px1',
  LevelPx2: 'LEVEL_Px2',
  LevelPx3: 'LEVEL_Px3',
  LevelDx1: 'LEVEL_Dx1',
  LevelDx2: 'LEVEL_Dx2',
  LevelDx3: 'LEVEL_Dx3',
  LevelFda1: 'LEVEL_Fda1',
  LevelFda2: 'LEVEL_Fda2',
  LevelFda3: 'LEVEL_Fda3',
  No: 'NO',
} as const;

export type IndicatorQueryRespHighestFdaLevelEnum =
  typeof IndicatorQueryRespHighestFdaLevelEnum[keyof typeof IndicatorQueryRespHighestFdaLevelEnum];
export const IndicatorQueryRespHighestPrognosticImplicationLevelEnum = {
  Level1: 'LEVEL_1',
  Level2: 'LEVEL_2',
  Level3A: 'LEVEL_3A',
  Level3B: 'LEVEL_3B',
  Level4: 'LEVEL_4',
  LevelR1: 'LEVEL_R1',
  LevelR2: 'LEVEL_R2',
  LevelPx1: 'LEVEL_Px1',
  LevelPx2: 'LEVEL_Px2',
  LevelPx3: 'LEVEL_Px3',
  LevelDx1: 'LEVEL_Dx1',
  LevelDx2: 'LEVEL_Dx2',
  LevelDx3: 'LEVEL_Dx3',
  LevelFda1: 'LEVEL_Fda1',
  LevelFda2: 'LEVEL_Fda2',
  LevelFda3: 'LEVEL_Fda3',
  No: 'NO',
} as const;

export type IndicatorQueryRespHighestPrognosticImplicationLevelEnum =
  typeof IndicatorQueryRespHighestPrognosticImplicationLevelEnum[keyof typeof IndicatorQueryRespHighestPrognosticImplicationLevelEnum];
export const IndicatorQueryRespHighestResistanceLevelEnum = {
  Level1: 'LEVEL_1',
  Level2: 'LEVEL_2',
  Level3A: 'LEVEL_3A',
  Level3B: 'LEVEL_3B',
  Level4: 'LEVEL_4',
  LevelR1: 'LEVEL_R1',
  LevelR2: 'LEVEL_R2',
  LevelPx1: 'LEVEL_Px1',
  LevelPx2: 'LEVEL_Px2',
  LevelPx3: 'LEVEL_Px3',
  LevelDx1: 'LEVEL_Dx1',
  LevelDx2: 'LEVEL_Dx2',
  LevelDx3: 'LEVEL_Dx3',
  LevelFda1: 'LEVEL_Fda1',
  LevelFda2: 'LEVEL_Fda2',
  LevelFda3: 'LEVEL_Fda3',
  No: 'NO',
} as const;

export type IndicatorQueryRespHighestResistanceLevelEnum =
  typeof IndicatorQueryRespHighestResistanceLevelEnum[keyof typeof IndicatorQueryRespHighestResistanceLevelEnum];
export const IndicatorQueryRespHighestSensitiveLevelEnum = {
  Level1: 'LEVEL_1',
  Level2: 'LEVEL_2',
  Level3A: 'LEVEL_3A',
  Level3B: 'LEVEL_3B',
  Level4: 'LEVEL_4',
  LevelR1: 'LEVEL_R1',
  LevelR2: 'LEVEL_R2',
  LevelPx1: 'LEVEL_Px1',
  LevelPx2: 'LEVEL_Px2',
  LevelPx3: 'LEVEL_Px3',
  LevelDx1: 'LEVEL_Dx1',
  LevelDx2: 'LEVEL_Dx2',
  LevelDx3: 'LEVEL_Dx3',
  LevelFda1: 'LEVEL_Fda1',
  LevelFda2: 'LEVEL_Fda2',
  LevelFda3: 'LEVEL_Fda3',
  No: 'NO',
} as const;

export type IndicatorQueryRespHighestSensitiveLevelEnum =
  typeof IndicatorQueryRespHighestSensitiveLevelEnum[keyof typeof IndicatorQueryRespHighestSensitiveLevelEnum];
export const IndicatorQueryRespOtherSignificantResistanceLevelsEnum = {
  Level1: 'LEVEL_1',
  Level2: 'LEVEL_2',
  Level3A: 'LEVEL_3A',
  Level3B: 'LEVEL_3B',
  Level4: 'LEVEL_4',
  LevelR1: 'LEVEL_R1',
  LevelR2: 'LEVEL_R2',
  LevelPx1: 'LEVEL_Px1',
  LevelPx2: 'LEVEL_Px2',
  LevelPx3: 'LEVEL_Px3',
  LevelDx1: 'LEVEL_Dx1',
  LevelDx2: 'LEVEL_Dx2',
  LevelDx3: 'LEVEL_Dx3',
  LevelFda1: 'LEVEL_Fda1',
  LevelFda2: 'LEVEL_Fda2',
  LevelFda3: 'LEVEL_Fda3',
  No: 'NO',
} as const;

export type IndicatorQueryRespOtherSignificantResistanceLevelsEnum =
  typeof IndicatorQueryRespOtherSignificantResistanceLevelsEnum[keyof typeof IndicatorQueryRespOtherSignificantResistanceLevelsEnum];
export const IndicatorQueryRespOtherSignificantSensitiveLevelsEnum = {
  Level1: 'LEVEL_1',
  Level2: 'LEVEL_2',
  Level3A: 'LEVEL_3A',
  Level3B: 'LEVEL_3B',
  Level4: 'LEVEL_4',
  LevelR1: 'LEVEL_R1',
  LevelR2: 'LEVEL_R2',
  LevelPx1: 'LEVEL_Px1',
  LevelPx2: 'LEVEL_Px2',
  LevelPx3: 'LEVEL_Px3',
  LevelDx1: 'LEVEL_Dx1',
  LevelDx2: 'LEVEL_Dx2',
  LevelDx3: 'LEVEL_Dx3',
  LevelFda1: 'LEVEL_Fda1',
  LevelFda2: 'LEVEL_Fda2',
  LevelFda3: 'LEVEL_Fda3',
  No: 'NO',
} as const;

export type IndicatorQueryRespOtherSignificantSensitiveLevelsEnum =
  typeof IndicatorQueryRespOtherSignificantSensitiveLevelsEnum[keyof typeof IndicatorQueryRespOtherSignificantSensitiveLevelsEnum];

/**
 *
 * @export
 * @interface IndicatorQueryTreatment
 */
export interface IndicatorQueryTreatment {
  /**
   *
   * @type {Array<ArticleAbstract>}
   * @memberof IndicatorQueryTreatment
   */
  abstracts?: Array<ArticleAbstract>;
  /**
   *
   * @type {Array<string>}
   * @memberof IndicatorQueryTreatment
   */
  alterations?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof IndicatorQueryTreatment
   */
  approvedIndications?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof IndicatorQueryTreatment
   */
  description?: string;
  /**
   *
   * @type {Array<Drug>}
   * @memberof IndicatorQueryTreatment
   */
  drugs?: Array<Drug>;
  /**
   *
   * @type {string}
   * @memberof IndicatorQueryTreatment
   */
  fdaLevel?: IndicatorQueryTreatmentFdaLevelEnum;
  /**
   *
   * @type {string}
   * @memberof IndicatorQueryTreatment
   */
  level?: IndicatorQueryTreatmentLevelEnum;
  /**
   *
   * @type {TumorType}
   * @memberof IndicatorQueryTreatment
   */
  levelAssociatedCancerType?: TumorType;
  /**
   *
   * @type {Array<TumorType>}
   * @memberof IndicatorQueryTreatment
   */
  levelExcludedCancerTypes?: Array<TumorType>;
  /**
   *
   * @type {Array<string>}
   * @memberof IndicatorQueryTreatment
   */
  pmids?: Array<string>;
}

export const IndicatorQueryTreatmentFdaLevelEnum = {
  Level1: 'LEVEL_1',
  Level2: 'LEVEL_2',
  Level3A: 'LEVEL_3A',
  Level3B: 'LEVEL_3B',
  Level4: 'LEVEL_4',
  LevelR1: 'LEVEL_R1',
  LevelR2: 'LEVEL_R2',
  LevelPx1: 'LEVEL_Px1',
  LevelPx2: 'LEVEL_Px2',
  LevelPx3: 'LEVEL_Px3',
  LevelDx1: 'LEVEL_Dx1',
  LevelDx2: 'LEVEL_Dx2',
  LevelDx3: 'LEVEL_Dx3',
  LevelFda1: 'LEVEL_Fda1',
  LevelFda2: 'LEVEL_Fda2',
  LevelFda3: 'LEVEL_Fda3',
  No: 'NO',
} as const;

export type IndicatorQueryTreatmentFdaLevelEnum =
  typeof IndicatorQueryTreatmentFdaLevelEnum[keyof typeof IndicatorQueryTreatmentFdaLevelEnum];
export const IndicatorQueryTreatmentLevelEnum = {
  Level1: 'LEVEL_1',
  Level2: 'LEVEL_2',
  Level3A: 'LEVEL_3A',
  Level3B: 'LEVEL_3B',
  Level4: 'LEVEL_4',
  LevelR1: 'LEVEL_R1',
  LevelR2: 'LEVEL_R2',
  LevelPx1: 'LEVEL_Px1',
  LevelPx2: 'LEVEL_Px2',
  LevelPx3: 'LEVEL_Px3',
  LevelDx1: 'LEVEL_Dx1',
  LevelDx2: 'LEVEL_Dx2',
  LevelDx3: 'LEVEL_Dx3',
  LevelFda1: 'LEVEL_Fda1',
  LevelFda2: 'LEVEL_Fda2',
  LevelFda3: 'LEVEL_Fda3',
  No: 'NO',
} as const;

export type IndicatorQueryTreatmentLevelEnum = typeof IndicatorQueryTreatmentLevelEnum[keyof typeof IndicatorQueryTreatmentLevelEnum];

/**
 *
 * @export
 * @interface InfoLevel
 */
export interface InfoLevel {
  /**
   *
   * @type {string}
   * @memberof InfoLevel
   */
  colorHex?: string;
  /**
   *
   * @type {string}
   * @memberof InfoLevel
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof InfoLevel
   */
  htmlDescription?: string;
  /**
   *
   * @type {string}
   * @memberof InfoLevel
   */
  levelOfEvidence?: InfoLevelLevelOfEvidenceEnum;
}

export const InfoLevelLevelOfEvidenceEnum = {
  Level1: 'LEVEL_1',
  Level2: 'LEVEL_2',
  Level3A: 'LEVEL_3A',
  Level3B: 'LEVEL_3B',
  Level4: 'LEVEL_4',
  LevelR1: 'LEVEL_R1',
  LevelR2: 'LEVEL_R2',
  LevelPx1: 'LEVEL_Px1',
  LevelPx2: 'LEVEL_Px2',
  LevelPx3: 'LEVEL_Px3',
  LevelDx1: 'LEVEL_Dx1',
  LevelDx2: 'LEVEL_Dx2',
  LevelDx3: 'LEVEL_Dx3',
  LevelFda1: 'LEVEL_Fda1',
  LevelFda2: 'LEVEL_Fda2',
  LevelFda3: 'LEVEL_Fda3',
  No: 'NO',
} as const;

export type InfoLevelLevelOfEvidenceEnum = typeof InfoLevelLevelOfEvidenceEnum[keyof typeof InfoLevelLevelOfEvidenceEnum];

/**
 * OncoTree Cancer Type
 * @export
 * @interface MainType
 */
export interface MainType {
  /**
   *
   * @type {number}
   * @memberof MainType
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof MainType
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof MainType
   */
  tumorForm?: MainTypeTumorFormEnum;
}

export const MainTypeTumorFormEnum = {
  Solid: 'SOLID',
  Liquid: 'LIQUID',
  Mixed: 'MIXED',
} as const;

export type MainTypeTumorFormEnum = typeof MainTypeTumorFormEnum[keyof typeof MainTypeTumorFormEnum];

/**
 *
 * @export
 * @interface MutationEffectResp
 */
export interface MutationEffectResp {
  /**
   *
   * @type {Citations}
   * @memberof MutationEffectResp
   */
  citations?: Citations;
  /**
   *
   * @type {string}
   * @memberof MutationEffectResp
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof MutationEffectResp
   */
  knownEffect?: string;
}
/**
 *
 * @export
 * @interface OncoKBInfo
 */
export interface OncoKBInfo {
  /**
   *
   * @type {SemVer}
   * @memberof OncoKBInfo
   */
  apiVersion?: SemVer;
  /**
   *
   * @type {SemVer}
   * @memberof OncoKBInfo
   */
  appVersion?: SemVer;
  /**
   *
   * @type {Version}
   * @memberof OncoKBInfo
   */
  dataVersion?: Version;
  /**
   *
   * @type {Array<InfoLevel>}
   * @memberof OncoKBInfo
   */
  levels?: Array<InfoLevel>;
  /**
   *
   * @type {string}
   * @memberof OncoKBInfo
   */
  ncitVersion?: string;
  /**
   *
   * @type {string}
   * @memberof OncoKBInfo
   */
  oncoTreeVersion?: string;
  /**
   *
   * @type {boolean}
   * @memberof OncoKBInfo
   */
  publicInstance?: boolean;
}
/**
 *
 * @export
 * @interface Query
 */
export interface Query {
  /**
   *
   * @type {string}
   * @memberof Query
   */
  alteration?: string;
  /**
   *
   * @type {string}
   * @memberof Query
   */
  alterationType?: string;
  /**
   *
   * @type {string}
   * @memberof Query
   */
  consequence?: string;
  /**
   *
   * @type {number}
   * @memberof Query
   */
  entrezGeneId?: number;
  /**
   *
   * @type {string}
   * @memberof Query
   */
  hgvs?: string;
  /**
   *
   * @type {string}
   * @memberof Query
   */
  hugoSymbol?: string;
  /**
   *
   * @type {string}
   * @memberof Query
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof Query
   */
  proteinEnd?: number;
  /**
   *
   * @type {number}
   * @memberof Query
   */
  proteinStart?: number;
  /**
   *
   * @type {string}
   * @memberof Query
   */
  referenceGenome?: QueryReferenceGenomeEnum;
  /**
   *
   * @type {string}
   * @memberof Query
   */
  svType?: QuerySvTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Query
   */
  tumorType?: string;
}

export const QueryReferenceGenomeEnum = {
  Grch37: 'GRCh37',
  Grch38: 'GRCh38',
} as const;

export type QueryReferenceGenomeEnum = typeof QueryReferenceGenomeEnum[keyof typeof QueryReferenceGenomeEnum];
export const QuerySvTypeEnum = {
  Deletion: 'DELETION',
  Translocation: 'TRANSLOCATION',
  Duplication: 'DUPLICATION',
  Insertion: 'INSERTION',
  Inversion: 'INVERSION',
  Fusion: 'FUSION',
  Unknown: 'UNKNOWN',
} as const;

export type QuerySvTypeEnum = typeof QuerySvTypeEnum[keyof typeof QuerySvTypeEnum];

/**
 *
 * @export
 * @interface QueryGene
 */
export interface QueryGene {
  /**
   *
   * @type {number}
   * @memberof QueryGene
   */
  entrezGeneId?: number;
  /**
   *
   * @type {string}
   * @memberof QueryGene
   */
  hugoSymbol?: string;
}
/**
 *
 * @export
 * @interface ResponseEntity
 */
export interface ResponseEntity {
  /**
   *
   * @type {object}
   * @memberof ResponseEntity
   */
  body?: object;
  /**
   *
   * @type {string}
   * @memberof ResponseEntity
   */
  statusCode?: ResponseEntityStatusCodeEnum;
}

export const ResponseEntityStatusCodeEnum = {
  _100: '100',
  _101: '101',
  _102: '102',
  _103: '103',
  _200: '200',
  _201: '201',
  _202: '202',
  _203: '203',
  _204: '204',
  _205: '205',
  _206: '206',
  _207: '207',
  _208: '208',
  _226: '226',
  _300: '300',
  _301: '301',
  _302: '302',
  _303: '303',
  _304: '304',
  _305: '305',
  _307: '307',
  _308: '308',
  _400: '400',
  _401: '401',
  _402: '402',
  _403: '403',
  _404: '404',
  _405: '405',
  _406: '406',
  _407: '407',
  _408: '408',
  _409: '409',
  _410: '410',
  _411: '411',
  _412: '412',
  _413: '413',
  _414: '414',
  _415: '415',
  _416: '416',
  _417: '417',
  _418: '418',
  _419: '419',
  _420: '420',
  _421: '421',
  _422: '422',
  _423: '423',
  _424: '424',
  _426: '426',
  _428: '428',
  _429: '429',
  _431: '431',
  _500: '500',
  _501: '501',
  _502: '502',
  _503: '503',
  _504: '504',
  _505: '505',
  _506: '506',
  _507: '507',
  _508: '508',
  _509: '509',
  _510: '510',
  _511: '511',
} as const;

export type ResponseEntityStatusCodeEnum = typeof ResponseEntityStatusCodeEnum[keyof typeof ResponseEntityStatusCodeEnum];

/**
 *
 * @export
 * @interface SemVer
 */
export interface SemVer {
  /**
   *
   * @type {number}
   * @memberof SemVer
   */
  major?: number;
  /**
   *
   * @type {number}
   * @memberof SemVer
   */
  minor?: number;
  /**
   *
   * @type {number}
   * @memberof SemVer
   */
  patch?: number;
  /**
   *
   * @type {boolean}
   * @memberof SemVer
   */
  stable?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof SemVer
   */
  suffixTokens?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof SemVer
   */
  version?: string;
}
/**
 *
 * @export
 * @interface Treatment
 */
export interface Treatment {
  /**
   *
   * @type {Array<string>}
   * @memberof Treatment
   */
  approvedIndications?: Array<string>;
  /**
   *
   * @type {Array<TreatmentDrug>}
   * @memberof Treatment
   */
  drugs?: Array<TreatmentDrug>;
  /**
   *
   * @type {number}
   * @memberof Treatment
   */
  priority?: number;
}
/**
 *
 * @export
 * @interface TreatmentDrug
 */
export interface TreatmentDrug {
  /**
   *
   * @type {number}
   * @memberof TreatmentDrug
   */
  priority?: number;
  /**
   *
   * @type {TreatmentDrugId}
   * @memberof TreatmentDrug
   */
  treatmentDrugId?: TreatmentDrugId;
}
/**
 *
 * @export
 * @interface TreatmentDrugId
 */
export interface TreatmentDrugId {
  /**
   *
   * @type {Drug}
   * @memberof TreatmentDrugId
   */
  drug?: Drug;
}
/**
 *
 * @export
 * @interface Trial
 */
export interface Trial {
  /**
   *
   * @type {Array<Arms>}
   * @memberof Trial
   */
  arms?: Array<Arms>;
  /**
   *
   * @type {string}
   * @memberof Trial
   */
  briefTitle?: string;
  /**
   *
   * @type {string}
   * @memberof Trial
   */
  currentTrialStatus?: string;
  /**
   *
   * @type {boolean}
   * @memberof Trial
   */
  isUSTrial?: boolean;
  /**
   *
   * @type {string}
   * @memberof Trial
   */
  nctId?: string;
  /**
   *
   * @type {string}
   * @memberof Trial
   */
  principalInvestigator?: string;
}
/**
 * OncoTree Detailed Cancer Type
 * @export
 * @interface TumorType
 */
export interface TumorType {
  /**
   *
   * @type {{ [key: string]: TumorType; }}
   * @memberof TumorType
   */
  children?: { [key: string]: TumorType };
  /**
   *
   * @type {string}
   * @memberof TumorType
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof TumorType
   */
  color?: string;
  /**
   *
   * @type {number}
   * @memberof TumorType
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof TumorType
   */
  level?: number;
  /**
   *
   * @type {MainType}
   * @memberof TumorType
   */
  mainType?: MainType;
  /**
   *
   * @type {string}
   * @memberof TumorType
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof TumorType
   */
  parent?: string;
  /**
   *
   * @type {string}
   * @memberof TumorType
   */
  tissue?: string;
  /**
   *
   * @type {string}
   * @memberof TumorType
   */
  tumorForm?: TumorTypeTumorFormEnum;
}

export const TumorTypeTumorFormEnum = {
  Solid: 'SOLID',
  Liquid: 'LIQUID',
  Mixed: 'MIXED',
} as const;

export type TumorTypeTumorFormEnum = typeof TumorTypeTumorFormEnum[keyof typeof TumorTypeTumorFormEnum];

/**
 *
 * @export
 * @interface VariantConsequence
 */
export interface VariantConsequence {
  /**
   *
   * @type {string}
   * @memberof VariantConsequence
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof VariantConsequence
   */
  isGenerallyTruncating?: boolean;
  /**
   *
   * @type {string}
   * @memberof VariantConsequence
   */
  term?: string;
}
/**
 *
 * @export
 * @interface VariantSearchQuery
 */
export interface VariantSearchQuery {
  /**
   *
   * @type {string}
   * @memberof VariantSearchQuery
   */
  consequence?: string;
  /**
   *
   * @type {number}
   * @memberof VariantSearchQuery
   */
  entrezGeneId?: number;
  /**
   *
   * @type {string}
   * @memberof VariantSearchQuery
   */
  hgvs?: string;
  /**
   *
   * @type {string}
   * @memberof VariantSearchQuery
   */
  hugoSymbol?: string;
  /**
   *
   * @type {number}
   * @memberof VariantSearchQuery
   */
  proteinEnd?: number;
  /**
   *
   * @type {number}
   * @memberof VariantSearchQuery
   */
  proteinStart?: number;
  /**
   *
   * @type {string}
   * @memberof VariantSearchQuery
   */
  referenceGenome?: VariantSearchQueryReferenceGenomeEnum;
  /**
   *
   * @type {string}
   * @memberof VariantSearchQuery
   */
  variant?: string;
  /**
   *
   * @type {string}
   * @memberof VariantSearchQuery
   */
  variantType?: string;
}

export const VariantSearchQueryReferenceGenomeEnum = {
  Grch37: 'GRCh37',
  Grch38: 'GRCh38',
} as const;

export type VariantSearchQueryReferenceGenomeEnum =
  typeof VariantSearchQueryReferenceGenomeEnum[keyof typeof VariantSearchQueryReferenceGenomeEnum];

/**
 *
 * @export
 * @interface Version
 */
export interface Version {
  /**
   *
   * @type {string}
   * @memberof Version
   */
  date?: string;
  /**
   *
   * @type {string}
   * @memberof Version
   */
  version?: string;
}

/**
 * AnnotationsApi - axios parameter creator
 * @export
 */
export const AnnotationsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Annotate copy number alteration.
     * @summary annotateCopyNumberAlterationsGet
     * @param {'AMPLIFICATION' | 'DELETION' | 'GAIN' | 'LOSS'} copyNameAlterationType Copy number alteration type
     * @param {string} [hugoSymbol] The gene symbol used in Human Genome Organisation. Example: BRAF
     * @param {number} [entrezGeneId] The entrez gene ID. (Higher priority than hugoSymbol). Example: 673
     * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
     * @param {string} [tumorType] OncoTree(http://oncotree.info) tumor type name. The field supports OncoTree Code, OncoTree Name and OncoTree Main type. Example: Melanoma
     * @param {string} [evidenceType] Evidence type to compute. This could help to improve the performance if you only look for sub-content. Example: ONCOGENIC. All available evidence type are GENE_SUMMARY, MUTATION_SUMMARY, TUMOR_TYPE_SUMMARY, PROGNOSTIC_SUMMARY, DIAGNOSTIC_SUMMARY, ONCOGENIC, MUTATION_EFFECT, PROGNOSTIC_IMPLICATION, DIAGNOSTIC_IMPLICATION, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE. For multiple evidence types query, use \&#39;,\&#39; as separator.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    annotateCopyNumberAlterationsGetUsingGET: async (
      copyNameAlterationType: 'AMPLIFICATION' | 'DELETION' | 'GAIN' | 'LOSS',
      hugoSymbol?: string,
      entrezGeneId?: number,
      referenceGenome?: string,
      tumorType?: string,
      evidenceType?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'copyNameAlterationType' is not null or undefined
      assertParamExists('annotateCopyNumberAlterationsGetUsingGET', 'copyNameAlterationType', copyNameAlterationType);
      const localVarPath = `/annotate/copyNumberAlterations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (hugoSymbol !== undefined) {
        localVarQueryParameter['hugoSymbol'] = hugoSymbol;
      }

      if (entrezGeneId !== undefined) {
        localVarQueryParameter['entrezGeneId'] = entrezGeneId;
      }

      if (copyNameAlterationType !== undefined) {
        localVarQueryParameter['copyNameAlterationType'] = copyNameAlterationType;
      }

      if (referenceGenome !== undefined) {
        localVarQueryParameter['referenceGenome'] = referenceGenome;
      }

      if (tumorType !== undefined) {
        localVarQueryParameter['tumorType'] = tumorType;
      }

      if (evidenceType !== undefined) {
        localVarQueryParameter['evidenceType'] = evidenceType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Annotate copy number alterations.
     * @summary annotateCopyNumberAlterationsPost
     * @param {Array<AnnotateCopyNumberAlterationQuery>} body List of queries. Please see swagger.json for request body format.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    annotateCopyNumberAlterationsPostUsingPOST: async (
      body: Array<AnnotateCopyNumberAlterationQuery>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('annotateCopyNumberAlterationsPostUsingPOST', 'body', body);
      const localVarPath = `/annotate/copyNumberAlterations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Annotate mutation by genomic change.
     * @summary annotateMutationsByGenomicChangeGet
     * @param {string} genomicLocation Genomic location. Example: 7,140453136,140453136,A,T
     * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
     * @param {string} [tumorType] OncoTree(http://oncotree.info) tumor type name. The field supports OncoTree Code, OncoTree Name and OncoTree Main type. Example: Melanoma
     * @param {string} [evidenceType] Evidence type to compute. This could help to improve the performance if you only look for sub-content. Example: ONCOGENIC. All available evidence type are GENE_SUMMARY, MUTATION_SUMMARY, TUMOR_TYPE_SUMMARY, PROGNOSTIC_SUMMARY, DIAGNOSTIC_SUMMARY, ONCOGENIC, MUTATION_EFFECT, PROGNOSTIC_IMPLICATION, DIAGNOSTIC_IMPLICATION, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE. For multiple evidence types query, use \&#39;,\&#39; as separator.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    annotateMutationsByGenomicChangeGetUsingGET: async (
      genomicLocation: string,
      referenceGenome?: string,
      tumorType?: string,
      evidenceType?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'genomicLocation' is not null or undefined
      assertParamExists('annotateMutationsByGenomicChangeGetUsingGET', 'genomicLocation', genomicLocation);
      const localVarPath = `/annotate/mutations/byGenomicChange`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (genomicLocation !== undefined) {
        localVarQueryParameter['genomicLocation'] = genomicLocation;
      }

      if (referenceGenome !== undefined) {
        localVarQueryParameter['referenceGenome'] = referenceGenome;
      }

      if (tumorType !== undefined) {
        localVarQueryParameter['tumorType'] = tumorType;
      }

      if (evidenceType !== undefined) {
        localVarQueryParameter['evidenceType'] = evidenceType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Annotate mutations by genomic change.
     * @summary annotateMutationsByGenomicChangePost
     * @param {Array<AnnotateMutationByGenomicChangeQuery>} body List of queries. Please see swagger.json for request body format.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    annotateMutationsByGenomicChangePostUsingPOST: async (
      body: Array<AnnotateMutationByGenomicChangeQuery>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('annotateMutationsByGenomicChangePostUsingPOST', 'body', body);
      const localVarPath = `/annotate/mutations/byGenomicChange`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Annotate mutation by HGVSg.
     * @summary annotateMutationsByHGVSgGet
     * @param {string} hgvsg HGVS genomic format. Example: 7:g.140453136A&gt;T
     * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
     * @param {string} [tumorType] OncoTree(http://oncotree.info) tumor type name. The field supports OncoTree Code, OncoTree Name and OncoTree Main type. Example: Melanoma
     * @param {string} [evidenceType] Evidence type to compute. This could help to improve the performance if you only look for sub-content. Example: ONCOGENIC. All available evidence type are GENE_SUMMARY, MUTATION_SUMMARY, TUMOR_TYPE_SUMMARY, PROGNOSTIC_SUMMARY, DIAGNOSTIC_SUMMARY, ONCOGENIC, MUTATION_EFFECT, PROGNOSTIC_IMPLICATION, DIAGNOSTIC_IMPLICATION, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE. For multiple evidence types query, use \&#39;,\&#39; as separator.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    annotateMutationsByHGVSgGetUsingGET: async (
      hgvsg: string,
      referenceGenome?: string,
      tumorType?: string,
      evidenceType?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'hgvsg' is not null or undefined
      assertParamExists('annotateMutationsByHGVSgGetUsingGET', 'hgvsg', hgvsg);
      const localVarPath = `/annotate/mutations/byHGVSg`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (hgvsg !== undefined) {
        localVarQueryParameter['hgvsg'] = hgvsg;
      }

      if (referenceGenome !== undefined) {
        localVarQueryParameter['referenceGenome'] = referenceGenome;
      }

      if (tumorType !== undefined) {
        localVarQueryParameter['tumorType'] = tumorType;
      }

      if (evidenceType !== undefined) {
        localVarQueryParameter['evidenceType'] = evidenceType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Annotate mutations by genomic change.
     * @summary annotateMutationsByHGVSgPost
     * @param {Array<AnnotateMutationByHGVSgQuery>} body List of queries. Please see swagger.json for request body format.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    annotateMutationsByHGVSgPostUsingPOST: async (
      body: Array<AnnotateMutationByHGVSgQuery>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('annotateMutationsByHGVSgPostUsingPOST', 'body', body);
      const localVarPath = `/annotate/mutations/byHGVSg`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Annotate mutation by protein change.
     * @summary annotateMutationsByProteinChangeGet
     * @param {string} [hugoSymbol] The gene symbol used in Human Genome Organisation. Example: BRAF
     * @param {number} [entrezGeneId] The entrez gene ID. (Higher priority than hugoSymbol). Example: 673
     * @param {string} [alteration] Protein Change. Example: V600E
     * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
     * @param {'feature_truncation' | 'frameshift_variant' | 'inframe_deletion' | 'inframe_insertion' | 'start_lost' | 'missense_variant' | 'splice_region_variant' | 'stop_gained' | 'synonymous_variant' | 'intron_variant'} [consequence] Consequence. Exacmple: missense_variant
     * @param {number} [proteinStart] Protein Start. Example: 600
     * @param {number} [proteinEnd] Protein End. Example: 600
     * @param {string} [tumorType] OncoTree(http://oncotree.info) tumor type name. The field supports OncoTree Code, OncoTree Name and OncoTree Main type. Example: Melanoma
     * @param {string} [evidenceType] Evidence type to compute. This could help to improve the performance if you only look for sub-content. Example: ONCOGENIC. All available evidence type are GENE_SUMMARY, MUTATION_SUMMARY, TUMOR_TYPE_SUMMARY, PROGNOSTIC_SUMMARY, DIAGNOSTIC_SUMMARY, ONCOGENIC, MUTATION_EFFECT, PROGNOSTIC_IMPLICATION, DIAGNOSTIC_IMPLICATION, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE. For multiple evidence types query, use \&#39;,\&#39; as separator.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    annotateMutationsByProteinChangeGetUsingGET: async (
      hugoSymbol?: string,
      entrezGeneId?: number,
      alteration?: string,
      referenceGenome?: string,
      consequence?:
        | 'feature_truncation'
        | 'frameshift_variant'
        | 'inframe_deletion'
        | 'inframe_insertion'
        | 'start_lost'
        | 'missense_variant'
        | 'splice_region_variant'
        | 'stop_gained'
        | 'synonymous_variant'
        | 'intron_variant',
      proteinStart?: number,
      proteinEnd?: number,
      tumorType?: string,
      evidenceType?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/annotate/mutations/byProteinChange`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (hugoSymbol !== undefined) {
        localVarQueryParameter['hugoSymbol'] = hugoSymbol;
      }

      if (entrezGeneId !== undefined) {
        localVarQueryParameter['entrezGeneId'] = entrezGeneId;
      }

      if (alteration !== undefined) {
        localVarQueryParameter['alteration'] = alteration;
      }

      if (referenceGenome !== undefined) {
        localVarQueryParameter['referenceGenome'] = referenceGenome;
      }

      if (consequence !== undefined) {
        localVarQueryParameter['consequence'] = consequence;
      }

      if (proteinStart !== undefined) {
        localVarQueryParameter['proteinStart'] = proteinStart;
      }

      if (proteinEnd !== undefined) {
        localVarQueryParameter['proteinEnd'] = proteinEnd;
      }

      if (tumorType !== undefined) {
        localVarQueryParameter['tumorType'] = tumorType;
      }

      if (evidenceType !== undefined) {
        localVarQueryParameter['evidenceType'] = evidenceType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Annotate mutations by protein change.
     * @summary annotateMutationsByProteinChangePost
     * @param {Array<AnnotateMutationByProteinChangeQuery>} body List of queries. Please see swagger.json for request body format.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    annotateMutationsByProteinChangePostUsingPOST: async (
      body: Array<AnnotateMutationByProteinChangeQuery>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('annotateMutationsByProteinChangePostUsingPOST', 'body', body);
      const localVarPath = `/annotate/mutations/byProteinChange`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Annotate structural variant.
     * @summary annotateStructuralVariantsGet
     * @param {'DELETION' | 'TRANSLOCATION' | 'DUPLICATION' | 'INSERTION' | 'INVERSION' | 'FUSION' | 'UNKNOWN'} structuralVariantType Structural variant type
     * @param {boolean} isFunctionalFusion Whether is functional fusion
     * @param {string} [hugoSymbolA] The gene symbol A used in Human Genome Organisation. Example: ABL1
     * @param {number} [entrezGeneIdA] The entrez gene ID A. (Higher priority than hugoSymbolA) Example: 25
     * @param {string} [hugoSymbolB] The gene symbol B used in Human Genome Organisation.Example: BCR
     * @param {number} [entrezGeneIdB] The entrez gene ID B. (Higher priority than hugoSymbolB) Example: 613
     * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
     * @param {string} [tumorType] OncoTree(http://oncotree.info) tumor type name. The field supports OncoTree Code, OncoTree Name and OncoTree Main type. Example: Melanoma
     * @param {string} [evidenceType] Evidence type to compute. This could help to improve the performance if you only look for sub-content. Example: ONCOGENIC. All available evidence type are GENE_SUMMARY, MUTATION_SUMMARY, TUMOR_TYPE_SUMMARY, PROGNOSTIC_SUMMARY, DIAGNOSTIC_SUMMARY, ONCOGENIC, MUTATION_EFFECT, PROGNOSTIC_IMPLICATION, DIAGNOSTIC_IMPLICATION, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE. For multiple evidence types query, use \&#39;,\&#39; as separator.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    annotateStructuralVariantsGetUsingGET: async (
      structuralVariantType: 'DELETION' | 'TRANSLOCATION' | 'DUPLICATION' | 'INSERTION' | 'INVERSION' | 'FUSION' | 'UNKNOWN',
      isFunctionalFusion: boolean,
      hugoSymbolA?: string,
      entrezGeneIdA?: number,
      hugoSymbolB?: string,
      entrezGeneIdB?: number,
      referenceGenome?: string,
      tumorType?: string,
      evidenceType?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'structuralVariantType' is not null or undefined
      assertParamExists('annotateStructuralVariantsGetUsingGET', 'structuralVariantType', structuralVariantType);
      // verify required parameter 'isFunctionalFusion' is not null or undefined
      assertParamExists('annotateStructuralVariantsGetUsingGET', 'isFunctionalFusion', isFunctionalFusion);
      const localVarPath = `/annotate/structuralVariants`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (hugoSymbolA !== undefined) {
        localVarQueryParameter['hugoSymbolA'] = hugoSymbolA;
      }

      if (entrezGeneIdA !== undefined) {
        localVarQueryParameter['entrezGeneIdA'] = entrezGeneIdA;
      }

      if (hugoSymbolB !== undefined) {
        localVarQueryParameter['hugoSymbolB'] = hugoSymbolB;
      }

      if (entrezGeneIdB !== undefined) {
        localVarQueryParameter['entrezGeneIdB'] = entrezGeneIdB;
      }

      if (structuralVariantType !== undefined) {
        localVarQueryParameter['structuralVariantType'] = structuralVariantType;
      }

      if (isFunctionalFusion !== undefined) {
        localVarQueryParameter['isFunctionalFusion'] = isFunctionalFusion;
      }

      if (referenceGenome !== undefined) {
        localVarQueryParameter['referenceGenome'] = referenceGenome;
      }

      if (tumorType !== undefined) {
        localVarQueryParameter['tumorType'] = tumorType;
      }

      if (evidenceType !== undefined) {
        localVarQueryParameter['evidenceType'] = evidenceType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Annotate structural variants.
     * @summary annotateStructuralVariantsPost
     * @param {Array<AnnotateStructuralVariantQuery>} body List of queries. Please see swagger.json for request body format.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    annotateStructuralVariantsPostUsingPOST: async (
      body: Array<AnnotateStructuralVariantQuery>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('annotateStructuralVariantsPostUsingPOST', 'body', body);
      const localVarPath = `/annotate/structuralVariants`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get annotations based on search
     * @summary annotationSearchGet
     * @param {string} query The search query, it could be hugoSymbol, variant or cancer type. At least two characters. Maximum two keywords are supported, separated by space
     * @param {number} [limit] The limit of returned result.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    annotationSearchGetUsingGET: async (query: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('annotationSearchGetUsingGET', 'query', query);
      const localVarPath = `/annotation/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AnnotationsApi - functional programming interface
 * @export
 */
export const AnnotationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AnnotationsApiAxiosParamCreator(configuration);
  return {
    /**
     * Annotate copy number alteration.
     * @summary annotateCopyNumberAlterationsGet
     * @param {'AMPLIFICATION' | 'DELETION' | 'GAIN' | 'LOSS'} copyNameAlterationType Copy number alteration type
     * @param {string} [hugoSymbol] The gene symbol used in Human Genome Organisation. Example: BRAF
     * @param {number} [entrezGeneId] The entrez gene ID. (Higher priority than hugoSymbol). Example: 673
     * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
     * @param {string} [tumorType] OncoTree(http://oncotree.info) tumor type name. The field supports OncoTree Code, OncoTree Name and OncoTree Main type. Example: Melanoma
     * @param {string} [evidenceType] Evidence type to compute. This could help to improve the performance if you only look for sub-content. Example: ONCOGENIC. All available evidence type are GENE_SUMMARY, MUTATION_SUMMARY, TUMOR_TYPE_SUMMARY, PROGNOSTIC_SUMMARY, DIAGNOSTIC_SUMMARY, ONCOGENIC, MUTATION_EFFECT, PROGNOSTIC_IMPLICATION, DIAGNOSTIC_IMPLICATION, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE. For multiple evidence types query, use \&#39;,\&#39; as separator.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async annotateCopyNumberAlterationsGetUsingGET(
      copyNameAlterationType: 'AMPLIFICATION' | 'DELETION' | 'GAIN' | 'LOSS',
      hugoSymbol?: string,
      entrezGeneId?: number,
      referenceGenome?: string,
      tumorType?: string,
      evidenceType?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndicatorQueryResp>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.annotateCopyNumberAlterationsGetUsingGET(
        copyNameAlterationType,
        hugoSymbol,
        entrezGeneId,
        referenceGenome,
        tumorType,
        evidenceType,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Annotate copy number alterations.
     * @summary annotateCopyNumberAlterationsPost
     * @param {Array<AnnotateCopyNumberAlterationQuery>} body List of queries. Please see swagger.json for request body format.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async annotateCopyNumberAlterationsPostUsingPOST(
      body: Array<AnnotateCopyNumberAlterationQuery>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IndicatorQueryResp>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.annotateCopyNumberAlterationsPostUsingPOST(body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Annotate mutation by genomic change.
     * @summary annotateMutationsByGenomicChangeGet
     * @param {string} genomicLocation Genomic location. Example: 7,140453136,140453136,A,T
     * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
     * @param {string} [tumorType] OncoTree(http://oncotree.info) tumor type name. The field supports OncoTree Code, OncoTree Name and OncoTree Main type. Example: Melanoma
     * @param {string} [evidenceType] Evidence type to compute. This could help to improve the performance if you only look for sub-content. Example: ONCOGENIC. All available evidence type are GENE_SUMMARY, MUTATION_SUMMARY, TUMOR_TYPE_SUMMARY, PROGNOSTIC_SUMMARY, DIAGNOSTIC_SUMMARY, ONCOGENIC, MUTATION_EFFECT, PROGNOSTIC_IMPLICATION, DIAGNOSTIC_IMPLICATION, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE. For multiple evidence types query, use \&#39;,\&#39; as separator.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async annotateMutationsByGenomicChangeGetUsingGET(
      genomicLocation: string,
      referenceGenome?: string,
      tumorType?: string,
      evidenceType?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndicatorQueryResp>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.annotateMutationsByGenomicChangeGetUsingGET(
        genomicLocation,
        referenceGenome,
        tumorType,
        evidenceType,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Annotate mutations by genomic change.
     * @summary annotateMutationsByGenomicChangePost
     * @param {Array<AnnotateMutationByGenomicChangeQuery>} body List of queries. Please see swagger.json for request body format.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async annotateMutationsByGenomicChangePostUsingPOST(
      body: Array<AnnotateMutationByGenomicChangeQuery>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IndicatorQueryResp>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.annotateMutationsByGenomicChangePostUsingPOST(body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Annotate mutation by HGVSg.
     * @summary annotateMutationsByHGVSgGet
     * @param {string} hgvsg HGVS genomic format. Example: 7:g.140453136A&gt;T
     * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
     * @param {string} [tumorType] OncoTree(http://oncotree.info) tumor type name. The field supports OncoTree Code, OncoTree Name and OncoTree Main type. Example: Melanoma
     * @param {string} [evidenceType] Evidence type to compute. This could help to improve the performance if you only look for sub-content. Example: ONCOGENIC. All available evidence type are GENE_SUMMARY, MUTATION_SUMMARY, TUMOR_TYPE_SUMMARY, PROGNOSTIC_SUMMARY, DIAGNOSTIC_SUMMARY, ONCOGENIC, MUTATION_EFFECT, PROGNOSTIC_IMPLICATION, DIAGNOSTIC_IMPLICATION, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE. For multiple evidence types query, use \&#39;,\&#39; as separator.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async annotateMutationsByHGVSgGetUsingGET(
      hgvsg: string,
      referenceGenome?: string,
      tumorType?: string,
      evidenceType?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndicatorQueryResp>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.annotateMutationsByHGVSgGetUsingGET(
        hgvsg,
        referenceGenome,
        tumorType,
        evidenceType,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Annotate mutations by genomic change.
     * @summary annotateMutationsByHGVSgPost
     * @param {Array<AnnotateMutationByHGVSgQuery>} body List of queries. Please see swagger.json for request body format.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async annotateMutationsByHGVSgPostUsingPOST(
      body: Array<AnnotateMutationByHGVSgQuery>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IndicatorQueryResp>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.annotateMutationsByHGVSgPostUsingPOST(body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Annotate mutation by protein change.
     * @summary annotateMutationsByProteinChangeGet
     * @param {string} [hugoSymbol] The gene symbol used in Human Genome Organisation. Example: BRAF
     * @param {number} [entrezGeneId] The entrez gene ID. (Higher priority than hugoSymbol). Example: 673
     * @param {string} [alteration] Protein Change. Example: V600E
     * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
     * @param {'feature_truncation' | 'frameshift_variant' | 'inframe_deletion' | 'inframe_insertion' | 'start_lost' | 'missense_variant' | 'splice_region_variant' | 'stop_gained' | 'synonymous_variant' | 'intron_variant'} [consequence] Consequence. Exacmple: missense_variant
     * @param {number} [proteinStart] Protein Start. Example: 600
     * @param {number} [proteinEnd] Protein End. Example: 600
     * @param {string} [tumorType] OncoTree(http://oncotree.info) tumor type name. The field supports OncoTree Code, OncoTree Name and OncoTree Main type. Example: Melanoma
     * @param {string} [evidenceType] Evidence type to compute. This could help to improve the performance if you only look for sub-content. Example: ONCOGENIC. All available evidence type are GENE_SUMMARY, MUTATION_SUMMARY, TUMOR_TYPE_SUMMARY, PROGNOSTIC_SUMMARY, DIAGNOSTIC_SUMMARY, ONCOGENIC, MUTATION_EFFECT, PROGNOSTIC_IMPLICATION, DIAGNOSTIC_IMPLICATION, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE. For multiple evidence types query, use \&#39;,\&#39; as separator.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async annotateMutationsByProteinChangeGetUsingGET(
      hugoSymbol?: string,
      entrezGeneId?: number,
      alteration?: string,
      referenceGenome?: string,
      consequence?:
        | 'feature_truncation'
        | 'frameshift_variant'
        | 'inframe_deletion'
        | 'inframe_insertion'
        | 'start_lost'
        | 'missense_variant'
        | 'splice_region_variant'
        | 'stop_gained'
        | 'synonymous_variant'
        | 'intron_variant',
      proteinStart?: number,
      proteinEnd?: number,
      tumorType?: string,
      evidenceType?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndicatorQueryResp>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.annotateMutationsByProteinChangeGetUsingGET(
        hugoSymbol,
        entrezGeneId,
        alteration,
        referenceGenome,
        consequence,
        proteinStart,
        proteinEnd,
        tumorType,
        evidenceType,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Annotate mutations by protein change.
     * @summary annotateMutationsByProteinChangePost
     * @param {Array<AnnotateMutationByProteinChangeQuery>} body List of queries. Please see swagger.json for request body format.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async annotateMutationsByProteinChangePostUsingPOST(
      body: Array<AnnotateMutationByProteinChangeQuery>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IndicatorQueryResp>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.annotateMutationsByProteinChangePostUsingPOST(body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Annotate structural variant.
     * @summary annotateStructuralVariantsGet
     * @param {'DELETION' | 'TRANSLOCATION' | 'DUPLICATION' | 'INSERTION' | 'INVERSION' | 'FUSION' | 'UNKNOWN'} structuralVariantType Structural variant type
     * @param {boolean} isFunctionalFusion Whether is functional fusion
     * @param {string} [hugoSymbolA] The gene symbol A used in Human Genome Organisation. Example: ABL1
     * @param {number} [entrezGeneIdA] The entrez gene ID A. (Higher priority than hugoSymbolA) Example: 25
     * @param {string} [hugoSymbolB] The gene symbol B used in Human Genome Organisation.Example: BCR
     * @param {number} [entrezGeneIdB] The entrez gene ID B. (Higher priority than hugoSymbolB) Example: 613
     * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
     * @param {string} [tumorType] OncoTree(http://oncotree.info) tumor type name. The field supports OncoTree Code, OncoTree Name and OncoTree Main type. Example: Melanoma
     * @param {string} [evidenceType] Evidence type to compute. This could help to improve the performance if you only look for sub-content. Example: ONCOGENIC. All available evidence type are GENE_SUMMARY, MUTATION_SUMMARY, TUMOR_TYPE_SUMMARY, PROGNOSTIC_SUMMARY, DIAGNOSTIC_SUMMARY, ONCOGENIC, MUTATION_EFFECT, PROGNOSTIC_IMPLICATION, DIAGNOSTIC_IMPLICATION, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE. For multiple evidence types query, use \&#39;,\&#39; as separator.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async annotateStructuralVariantsGetUsingGET(
      structuralVariantType: 'DELETION' | 'TRANSLOCATION' | 'DUPLICATION' | 'INSERTION' | 'INVERSION' | 'FUSION' | 'UNKNOWN',
      isFunctionalFusion: boolean,
      hugoSymbolA?: string,
      entrezGeneIdA?: number,
      hugoSymbolB?: string,
      entrezGeneIdB?: number,
      referenceGenome?: string,
      tumorType?: string,
      evidenceType?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndicatorQueryResp>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.annotateStructuralVariantsGetUsingGET(
        structuralVariantType,
        isFunctionalFusion,
        hugoSymbolA,
        entrezGeneIdA,
        hugoSymbolB,
        entrezGeneIdB,
        referenceGenome,
        tumorType,
        evidenceType,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Annotate structural variants.
     * @summary annotateStructuralVariantsPost
     * @param {Array<AnnotateStructuralVariantQuery>} body List of queries. Please see swagger.json for request body format.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async annotateStructuralVariantsPostUsingPOST(
      body: Array<AnnotateStructuralVariantQuery>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IndicatorQueryResp>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.annotateStructuralVariantsPostUsingPOST(body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get annotations based on search
     * @summary annotationSearchGet
     * @param {string} query The search query, it could be hugoSymbol, variant or cancer type. At least two characters. Maximum two keywords are supported, separated by space
     * @param {number} [limit] The limit of returned result.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async annotationSearchGetUsingGET(
      query: string,
      limit?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnnotationSearchResult>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.annotationSearchGetUsingGET(query, limit, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AnnotationsApi - factory interface
 * @export
 */
export const AnnotationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AnnotationsApiFp(configuration);
  return {
    /**
     * Annotate copy number alteration.
     * @summary annotateCopyNumberAlterationsGet
     * @param {'AMPLIFICATION' | 'DELETION' | 'GAIN' | 'LOSS'} copyNameAlterationType Copy number alteration type
     * @param {string} [hugoSymbol] The gene symbol used in Human Genome Organisation. Example: BRAF
     * @param {number} [entrezGeneId] The entrez gene ID. (Higher priority than hugoSymbol). Example: 673
     * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
     * @param {string} [tumorType] OncoTree(http://oncotree.info) tumor type name. The field supports OncoTree Code, OncoTree Name and OncoTree Main type. Example: Melanoma
     * @param {string} [evidenceType] Evidence type to compute. This could help to improve the performance if you only look for sub-content. Example: ONCOGENIC. All available evidence type are GENE_SUMMARY, MUTATION_SUMMARY, TUMOR_TYPE_SUMMARY, PROGNOSTIC_SUMMARY, DIAGNOSTIC_SUMMARY, ONCOGENIC, MUTATION_EFFECT, PROGNOSTIC_IMPLICATION, DIAGNOSTIC_IMPLICATION, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE. For multiple evidence types query, use \&#39;,\&#39; as separator.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    annotateCopyNumberAlterationsGetUsingGET(
      copyNameAlterationType: 'AMPLIFICATION' | 'DELETION' | 'GAIN' | 'LOSS',
      hugoSymbol?: string,
      entrezGeneId?: number,
      referenceGenome?: string,
      tumorType?: string,
      evidenceType?: string,
      options?: any
    ): AxiosPromise<IndicatorQueryResp> {
      return localVarFp
        .annotateCopyNumberAlterationsGetUsingGET(
          copyNameAlterationType,
          hugoSymbol,
          entrezGeneId,
          referenceGenome,
          tumorType,
          evidenceType,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Annotate copy number alterations.
     * @summary annotateCopyNumberAlterationsPost
     * @param {Array<AnnotateCopyNumberAlterationQuery>} body List of queries. Please see swagger.json for request body format.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    annotateCopyNumberAlterationsPostUsingPOST(
      body: Array<AnnotateCopyNumberAlterationQuery>,
      options?: any
    ): AxiosPromise<Array<IndicatorQueryResp>> {
      return localVarFp.annotateCopyNumberAlterationsPostUsingPOST(body, options).then(request => request(axios, basePath));
    },
    /**
     * Annotate mutation by genomic change.
     * @summary annotateMutationsByGenomicChangeGet
     * @param {string} genomicLocation Genomic location. Example: 7,140453136,140453136,A,T
     * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
     * @param {string} [tumorType] OncoTree(http://oncotree.info) tumor type name. The field supports OncoTree Code, OncoTree Name and OncoTree Main type. Example: Melanoma
     * @param {string} [evidenceType] Evidence type to compute. This could help to improve the performance if you only look for sub-content. Example: ONCOGENIC. All available evidence type are GENE_SUMMARY, MUTATION_SUMMARY, TUMOR_TYPE_SUMMARY, PROGNOSTIC_SUMMARY, DIAGNOSTIC_SUMMARY, ONCOGENIC, MUTATION_EFFECT, PROGNOSTIC_IMPLICATION, DIAGNOSTIC_IMPLICATION, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE. For multiple evidence types query, use \&#39;,\&#39; as separator.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    annotateMutationsByGenomicChangeGetUsingGET(
      genomicLocation: string,
      referenceGenome?: string,
      tumorType?: string,
      evidenceType?: string,
      options?: any
    ): AxiosPromise<IndicatorQueryResp> {
      return localVarFp
        .annotateMutationsByGenomicChangeGetUsingGET(genomicLocation, referenceGenome, tumorType, evidenceType, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Annotate mutations by genomic change.
     * @summary annotateMutationsByGenomicChangePost
     * @param {Array<AnnotateMutationByGenomicChangeQuery>} body List of queries. Please see swagger.json for request body format.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    annotateMutationsByGenomicChangePostUsingPOST(
      body: Array<AnnotateMutationByGenomicChangeQuery>,
      options?: any
    ): AxiosPromise<Array<IndicatorQueryResp>> {
      return localVarFp.annotateMutationsByGenomicChangePostUsingPOST(body, options).then(request => request(axios, basePath));
    },
    /**
     * Annotate mutation by HGVSg.
     * @summary annotateMutationsByHGVSgGet
     * @param {string} hgvsg HGVS genomic format. Example: 7:g.140453136A&gt;T
     * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
     * @param {string} [tumorType] OncoTree(http://oncotree.info) tumor type name. The field supports OncoTree Code, OncoTree Name and OncoTree Main type. Example: Melanoma
     * @param {string} [evidenceType] Evidence type to compute. This could help to improve the performance if you only look for sub-content. Example: ONCOGENIC. All available evidence type are GENE_SUMMARY, MUTATION_SUMMARY, TUMOR_TYPE_SUMMARY, PROGNOSTIC_SUMMARY, DIAGNOSTIC_SUMMARY, ONCOGENIC, MUTATION_EFFECT, PROGNOSTIC_IMPLICATION, DIAGNOSTIC_IMPLICATION, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE. For multiple evidence types query, use \&#39;,\&#39; as separator.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    annotateMutationsByHGVSgGetUsingGET(
      hgvsg: string,
      referenceGenome?: string,
      tumorType?: string,
      evidenceType?: string,
      options?: any
    ): AxiosPromise<IndicatorQueryResp> {
      return localVarFp
        .annotateMutationsByHGVSgGetUsingGET(hgvsg, referenceGenome, tumorType, evidenceType, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Annotate mutations by genomic change.
     * @summary annotateMutationsByHGVSgPost
     * @param {Array<AnnotateMutationByHGVSgQuery>} body List of queries. Please see swagger.json for request body format.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    annotateMutationsByHGVSgPostUsingPOST(
      body: Array<AnnotateMutationByHGVSgQuery>,
      options?: any
    ): AxiosPromise<Array<IndicatorQueryResp>> {
      return localVarFp.annotateMutationsByHGVSgPostUsingPOST(body, options).then(request => request(axios, basePath));
    },
    /**
     * Annotate mutation by protein change.
     * @summary annotateMutationsByProteinChangeGet
     * @param {string} [hugoSymbol] The gene symbol used in Human Genome Organisation. Example: BRAF
     * @param {number} [entrezGeneId] The entrez gene ID. (Higher priority than hugoSymbol). Example: 673
     * @param {string} [alteration] Protein Change. Example: V600E
     * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
     * @param {'feature_truncation' | 'frameshift_variant' | 'inframe_deletion' | 'inframe_insertion' | 'start_lost' | 'missense_variant' | 'splice_region_variant' | 'stop_gained' | 'synonymous_variant' | 'intron_variant'} [consequence] Consequence. Exacmple: missense_variant
     * @param {number} [proteinStart] Protein Start. Example: 600
     * @param {number} [proteinEnd] Protein End. Example: 600
     * @param {string} [tumorType] OncoTree(http://oncotree.info) tumor type name. The field supports OncoTree Code, OncoTree Name and OncoTree Main type. Example: Melanoma
     * @param {string} [evidenceType] Evidence type to compute. This could help to improve the performance if you only look for sub-content. Example: ONCOGENIC. All available evidence type are GENE_SUMMARY, MUTATION_SUMMARY, TUMOR_TYPE_SUMMARY, PROGNOSTIC_SUMMARY, DIAGNOSTIC_SUMMARY, ONCOGENIC, MUTATION_EFFECT, PROGNOSTIC_IMPLICATION, DIAGNOSTIC_IMPLICATION, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE. For multiple evidence types query, use \&#39;,\&#39; as separator.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    annotateMutationsByProteinChangeGetUsingGET(
      hugoSymbol?: string,
      entrezGeneId?: number,
      alteration?: string,
      referenceGenome?: string,
      consequence?:
        | 'feature_truncation'
        | 'frameshift_variant'
        | 'inframe_deletion'
        | 'inframe_insertion'
        | 'start_lost'
        | 'missense_variant'
        | 'splice_region_variant'
        | 'stop_gained'
        | 'synonymous_variant'
        | 'intron_variant',
      proteinStart?: number,
      proteinEnd?: number,
      tumorType?: string,
      evidenceType?: string,
      options?: any
    ): AxiosPromise<IndicatorQueryResp> {
      return localVarFp
        .annotateMutationsByProteinChangeGetUsingGET(
          hugoSymbol,
          entrezGeneId,
          alteration,
          referenceGenome,
          consequence,
          proteinStart,
          proteinEnd,
          tumorType,
          evidenceType,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Annotate mutations by protein change.
     * @summary annotateMutationsByProteinChangePost
     * @param {Array<AnnotateMutationByProteinChangeQuery>} body List of queries. Please see swagger.json for request body format.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    annotateMutationsByProteinChangePostUsingPOST(
      body: Array<AnnotateMutationByProteinChangeQuery>,
      options?: any
    ): AxiosPromise<Array<IndicatorQueryResp>> {
      return localVarFp.annotateMutationsByProteinChangePostUsingPOST(body, options).then(request => request(axios, basePath));
    },
    /**
     * Annotate structural variant.
     * @summary annotateStructuralVariantsGet
     * @param {'DELETION' | 'TRANSLOCATION' | 'DUPLICATION' | 'INSERTION' | 'INVERSION' | 'FUSION' | 'UNKNOWN'} structuralVariantType Structural variant type
     * @param {boolean} isFunctionalFusion Whether is functional fusion
     * @param {string} [hugoSymbolA] The gene symbol A used in Human Genome Organisation. Example: ABL1
     * @param {number} [entrezGeneIdA] The entrez gene ID A. (Higher priority than hugoSymbolA) Example: 25
     * @param {string} [hugoSymbolB] The gene symbol B used in Human Genome Organisation.Example: BCR
     * @param {number} [entrezGeneIdB] The entrez gene ID B. (Higher priority than hugoSymbolB) Example: 613
     * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
     * @param {string} [tumorType] OncoTree(http://oncotree.info) tumor type name. The field supports OncoTree Code, OncoTree Name and OncoTree Main type. Example: Melanoma
     * @param {string} [evidenceType] Evidence type to compute. This could help to improve the performance if you only look for sub-content. Example: ONCOGENIC. All available evidence type are GENE_SUMMARY, MUTATION_SUMMARY, TUMOR_TYPE_SUMMARY, PROGNOSTIC_SUMMARY, DIAGNOSTIC_SUMMARY, ONCOGENIC, MUTATION_EFFECT, PROGNOSTIC_IMPLICATION, DIAGNOSTIC_IMPLICATION, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE. For multiple evidence types query, use \&#39;,\&#39; as separator.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    annotateStructuralVariantsGetUsingGET(
      structuralVariantType: 'DELETION' | 'TRANSLOCATION' | 'DUPLICATION' | 'INSERTION' | 'INVERSION' | 'FUSION' | 'UNKNOWN',
      isFunctionalFusion: boolean,
      hugoSymbolA?: string,
      entrezGeneIdA?: number,
      hugoSymbolB?: string,
      entrezGeneIdB?: number,
      referenceGenome?: string,
      tumorType?: string,
      evidenceType?: string,
      options?: any
    ): AxiosPromise<IndicatorQueryResp> {
      return localVarFp
        .annotateStructuralVariantsGetUsingGET(
          structuralVariantType,
          isFunctionalFusion,
          hugoSymbolA,
          entrezGeneIdA,
          hugoSymbolB,
          entrezGeneIdB,
          referenceGenome,
          tumorType,
          evidenceType,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Annotate structural variants.
     * @summary annotateStructuralVariantsPost
     * @param {Array<AnnotateStructuralVariantQuery>} body List of queries. Please see swagger.json for request body format.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    annotateStructuralVariantsPostUsingPOST(
      body: Array<AnnotateStructuralVariantQuery>,
      options?: any
    ): AxiosPromise<Array<IndicatorQueryResp>> {
      return localVarFp.annotateStructuralVariantsPostUsingPOST(body, options).then(request => request(axios, basePath));
    },
    /**
     * Get annotations based on search
     * @summary annotationSearchGet
     * @param {string} query The search query, it could be hugoSymbol, variant or cancer type. At least two characters. Maximum two keywords are supported, separated by space
     * @param {number} [limit] The limit of returned result.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    annotationSearchGetUsingGET(query: string, limit?: number, options?: any): AxiosPromise<Array<AnnotationSearchResult>> {
      return localVarFp.annotationSearchGetUsingGET(query, limit, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * AnnotationsApi - object-oriented interface
 * @export
 * @class AnnotationsApi
 * @extends {BaseAPI}
 */
export class AnnotationsApi extends BaseAPI {
  /**
   * Annotate copy number alteration.
   * @summary annotateCopyNumberAlterationsGet
   * @param {'AMPLIFICATION' | 'DELETION' | 'GAIN' | 'LOSS'} copyNameAlterationType Copy number alteration type
   * @param {string} [hugoSymbol] The gene symbol used in Human Genome Organisation. Example: BRAF
   * @param {number} [entrezGeneId] The entrez gene ID. (Higher priority than hugoSymbol). Example: 673
   * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
   * @param {string} [tumorType] OncoTree(http://oncotree.info) tumor type name. The field supports OncoTree Code, OncoTree Name and OncoTree Main type. Example: Melanoma
   * @param {string} [evidenceType] Evidence type to compute. This could help to improve the performance if you only look for sub-content. Example: ONCOGENIC. All available evidence type are GENE_SUMMARY, MUTATION_SUMMARY, TUMOR_TYPE_SUMMARY, PROGNOSTIC_SUMMARY, DIAGNOSTIC_SUMMARY, ONCOGENIC, MUTATION_EFFECT, PROGNOSTIC_IMPLICATION, DIAGNOSTIC_IMPLICATION, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE. For multiple evidence types query, use \&#39;,\&#39; as separator.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public annotateCopyNumberAlterationsGetUsingGET(
    copyNameAlterationType: 'AMPLIFICATION' | 'DELETION' | 'GAIN' | 'LOSS',
    hugoSymbol?: string,
    entrezGeneId?: number,
    referenceGenome?: string,
    tumorType?: string,
    evidenceType?: string,
    options?: AxiosRequestConfig
  ) {
    return AnnotationsApiFp(this.configuration)
      .annotateCopyNumberAlterationsGetUsingGET(
        copyNameAlterationType,
        hugoSymbol,
        entrezGeneId,
        referenceGenome,
        tumorType,
        evidenceType,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Annotate copy number alterations.
   * @summary annotateCopyNumberAlterationsPost
   * @param {Array<AnnotateCopyNumberAlterationQuery>} body List of queries. Please see swagger.json for request body format.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public annotateCopyNumberAlterationsPostUsingPOST(body: Array<AnnotateCopyNumberAlterationQuery>, options?: AxiosRequestConfig) {
    return AnnotationsApiFp(this.configuration)
      .annotateCopyNumberAlterationsPostUsingPOST(body, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Annotate mutation by genomic change.
   * @summary annotateMutationsByGenomicChangeGet
   * @param {string} genomicLocation Genomic location. Example: 7,140453136,140453136,A,T
   * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
   * @param {string} [tumorType] OncoTree(http://oncotree.info) tumor type name. The field supports OncoTree Code, OncoTree Name and OncoTree Main type. Example: Melanoma
   * @param {string} [evidenceType] Evidence type to compute. This could help to improve the performance if you only look for sub-content. Example: ONCOGENIC. All available evidence type are GENE_SUMMARY, MUTATION_SUMMARY, TUMOR_TYPE_SUMMARY, PROGNOSTIC_SUMMARY, DIAGNOSTIC_SUMMARY, ONCOGENIC, MUTATION_EFFECT, PROGNOSTIC_IMPLICATION, DIAGNOSTIC_IMPLICATION, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE. For multiple evidence types query, use \&#39;,\&#39; as separator.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public annotateMutationsByGenomicChangeGetUsingGET(
    genomicLocation: string,
    referenceGenome?: string,
    tumorType?: string,
    evidenceType?: string,
    options?: AxiosRequestConfig
  ) {
    return AnnotationsApiFp(this.configuration)
      .annotateMutationsByGenomicChangeGetUsingGET(genomicLocation, referenceGenome, tumorType, evidenceType, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Annotate mutations by genomic change.
   * @summary annotateMutationsByGenomicChangePost
   * @param {Array<AnnotateMutationByGenomicChangeQuery>} body List of queries. Please see swagger.json for request body format.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public annotateMutationsByGenomicChangePostUsingPOST(body: Array<AnnotateMutationByGenomicChangeQuery>, options?: AxiosRequestConfig) {
    return AnnotationsApiFp(this.configuration)
      .annotateMutationsByGenomicChangePostUsingPOST(body, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Annotate mutation by HGVSg.
   * @summary annotateMutationsByHGVSgGet
   * @param {string} hgvsg HGVS genomic format. Example: 7:g.140453136A&gt;T
   * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
   * @param {string} [tumorType] OncoTree(http://oncotree.info) tumor type name. The field supports OncoTree Code, OncoTree Name and OncoTree Main type. Example: Melanoma
   * @param {string} [evidenceType] Evidence type to compute. This could help to improve the performance if you only look for sub-content. Example: ONCOGENIC. All available evidence type are GENE_SUMMARY, MUTATION_SUMMARY, TUMOR_TYPE_SUMMARY, PROGNOSTIC_SUMMARY, DIAGNOSTIC_SUMMARY, ONCOGENIC, MUTATION_EFFECT, PROGNOSTIC_IMPLICATION, DIAGNOSTIC_IMPLICATION, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE. For multiple evidence types query, use \&#39;,\&#39; as separator.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public annotateMutationsByHGVSgGetUsingGET(
    hgvsg: string,
    referenceGenome?: string,
    tumorType?: string,
    evidenceType?: string,
    options?: AxiosRequestConfig
  ) {
    return AnnotationsApiFp(this.configuration)
      .annotateMutationsByHGVSgGetUsingGET(hgvsg, referenceGenome, tumorType, evidenceType, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Annotate mutations by genomic change.
   * @summary annotateMutationsByHGVSgPost
   * @param {Array<AnnotateMutationByHGVSgQuery>} body List of queries. Please see swagger.json for request body format.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public annotateMutationsByHGVSgPostUsingPOST(body: Array<AnnotateMutationByHGVSgQuery>, options?: AxiosRequestConfig) {
    return AnnotationsApiFp(this.configuration)
      .annotateMutationsByHGVSgPostUsingPOST(body, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Annotate mutation by protein change.
   * @summary annotateMutationsByProteinChangeGet
   * @param {string} [hugoSymbol] The gene symbol used in Human Genome Organisation. Example: BRAF
   * @param {number} [entrezGeneId] The entrez gene ID. (Higher priority than hugoSymbol). Example: 673
   * @param {string} [alteration] Protein Change. Example: V600E
   * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
   * @param {'feature_truncation' | 'frameshift_variant' | 'inframe_deletion' | 'inframe_insertion' | 'start_lost' | 'missense_variant' | 'splice_region_variant' | 'stop_gained' | 'synonymous_variant' | 'intron_variant'} [consequence] Consequence. Exacmple: missense_variant
   * @param {number} [proteinStart] Protein Start. Example: 600
   * @param {number} [proteinEnd] Protein End. Example: 600
   * @param {string} [tumorType] OncoTree(http://oncotree.info) tumor type name. The field supports OncoTree Code, OncoTree Name and OncoTree Main type. Example: Melanoma
   * @param {string} [evidenceType] Evidence type to compute. This could help to improve the performance if you only look for sub-content. Example: ONCOGENIC. All available evidence type are GENE_SUMMARY, MUTATION_SUMMARY, TUMOR_TYPE_SUMMARY, PROGNOSTIC_SUMMARY, DIAGNOSTIC_SUMMARY, ONCOGENIC, MUTATION_EFFECT, PROGNOSTIC_IMPLICATION, DIAGNOSTIC_IMPLICATION, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE. For multiple evidence types query, use \&#39;,\&#39; as separator.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public annotateMutationsByProteinChangeGetUsingGET(
    hugoSymbol?: string,
    entrezGeneId?: number,
    alteration?: string,
    referenceGenome?: string,
    consequence?:
      | 'feature_truncation'
      | 'frameshift_variant'
      | 'inframe_deletion'
      | 'inframe_insertion'
      | 'start_lost'
      | 'missense_variant'
      | 'splice_region_variant'
      | 'stop_gained'
      | 'synonymous_variant'
      | 'intron_variant',
    proteinStart?: number,
    proteinEnd?: number,
    tumorType?: string,
    evidenceType?: string,
    options?: AxiosRequestConfig
  ) {
    return AnnotationsApiFp(this.configuration)
      .annotateMutationsByProteinChangeGetUsingGET(
        hugoSymbol,
        entrezGeneId,
        alteration,
        referenceGenome,
        consequence,
        proteinStart,
        proteinEnd,
        tumorType,
        evidenceType,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Annotate mutations by protein change.
   * @summary annotateMutationsByProteinChangePost
   * @param {Array<AnnotateMutationByProteinChangeQuery>} body List of queries. Please see swagger.json for request body format.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public annotateMutationsByProteinChangePostUsingPOST(body: Array<AnnotateMutationByProteinChangeQuery>, options?: AxiosRequestConfig) {
    return AnnotationsApiFp(this.configuration)
      .annotateMutationsByProteinChangePostUsingPOST(body, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Annotate structural variant.
   * @summary annotateStructuralVariantsGet
   * @param {'DELETION' | 'TRANSLOCATION' | 'DUPLICATION' | 'INSERTION' | 'INVERSION' | 'FUSION' | 'UNKNOWN'} structuralVariantType Structural variant type
   * @param {boolean} isFunctionalFusion Whether is functional fusion
   * @param {string} [hugoSymbolA] The gene symbol A used in Human Genome Organisation. Example: ABL1
   * @param {number} [entrezGeneIdA] The entrez gene ID A. (Higher priority than hugoSymbolA) Example: 25
   * @param {string} [hugoSymbolB] The gene symbol B used in Human Genome Organisation.Example: BCR
   * @param {number} [entrezGeneIdB] The entrez gene ID B. (Higher priority than hugoSymbolB) Example: 613
   * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
   * @param {string} [tumorType] OncoTree(http://oncotree.info) tumor type name. The field supports OncoTree Code, OncoTree Name and OncoTree Main type. Example: Melanoma
   * @param {string} [evidenceType] Evidence type to compute. This could help to improve the performance if you only look for sub-content. Example: ONCOGENIC. All available evidence type are GENE_SUMMARY, MUTATION_SUMMARY, TUMOR_TYPE_SUMMARY, PROGNOSTIC_SUMMARY, DIAGNOSTIC_SUMMARY, ONCOGENIC, MUTATION_EFFECT, PROGNOSTIC_IMPLICATION, DIAGNOSTIC_IMPLICATION, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE. For multiple evidence types query, use \&#39;,\&#39; as separator.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public annotateStructuralVariantsGetUsingGET(
    structuralVariantType: 'DELETION' | 'TRANSLOCATION' | 'DUPLICATION' | 'INSERTION' | 'INVERSION' | 'FUSION' | 'UNKNOWN',
    isFunctionalFusion: boolean,
    hugoSymbolA?: string,
    entrezGeneIdA?: number,
    hugoSymbolB?: string,
    entrezGeneIdB?: number,
    referenceGenome?: string,
    tumorType?: string,
    evidenceType?: string,
    options?: AxiosRequestConfig
  ) {
    return AnnotationsApiFp(this.configuration)
      .annotateStructuralVariantsGetUsingGET(
        structuralVariantType,
        isFunctionalFusion,
        hugoSymbolA,
        entrezGeneIdA,
        hugoSymbolB,
        entrezGeneIdB,
        referenceGenome,
        tumorType,
        evidenceType,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Annotate structural variants.
   * @summary annotateStructuralVariantsPost
   * @param {Array<AnnotateStructuralVariantQuery>} body List of queries. Please see swagger.json for request body format.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public annotateStructuralVariantsPostUsingPOST(body: Array<AnnotateStructuralVariantQuery>, options?: AxiosRequestConfig) {
    return AnnotationsApiFp(this.configuration)
      .annotateStructuralVariantsPostUsingPOST(body, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get annotations based on search
   * @summary annotationSearchGet
   * @param {string} query The search query, it could be hugoSymbol, variant or cancer type. At least two characters. Maximum two keywords are supported, separated by space
   * @param {number} [limit] The limit of returned result.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public annotationSearchGetUsingGET(query: string, limit?: number, options?: AxiosRequestConfig) {
    return AnnotationsApiFp(this.configuration)
      .annotationSearchGetUsingGET(query, limit, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CancerGenesApi - axios parameter creator
 * @export
 */
export const CancerGenesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get All Actionable Variants.
     * @summary utilsAllActionableVariantsGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilsAllActionableVariantsGetUsingGET: async (version?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/utils/allActionableVariants`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get All Actionable Variants in text file.
     * @summary utilsAllActionableVariantsTxtGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilsAllActionableVariantsTxtGetUsingGET: async (version?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/utils/allActionableVariants.txt`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get All Annotated Variants.
     * @summary utilsAllAnnotatedVariantsGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilsAllAnnotatedVariantsGetUsingGET: async (version?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/utils/allAnnotatedVariants`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get All Annotated Variants in text file.
     * @summary utilsAllAnnotatedVariantsTxtGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilsAllAnnotatedVariantsTxtGetUsingGET: async (version?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/utils/allAnnotatedVariants.txt`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get list of genes OncoKB curated
     * @summary utilsAllCuratedGenesGet
     * @param {string} [version] The data version
     * @param {boolean} [includeEvidence] Include gene summary and background
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilsAllCuratedGenesGetUsingGET: async (
      version?: string,
      includeEvidence?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/utils/allCuratedGenes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      if (includeEvidence !== undefined) {
        localVarQueryParameter['includeEvidence'] = includeEvidence;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get list of genes OncoKB curated in text file.
     * @summary utilsAllCuratedGenesTxtGet
     * @param {string} [version] The data version
     * @param {boolean} [includeEvidence] Include gene summary and background
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilsAllCuratedGenesTxtGetUsingGET: async (
      version?: string,
      includeEvidence?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/utils/allCuratedGenes.txt`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      if (includeEvidence !== undefined) {
        localVarQueryParameter['includeEvidence'] = includeEvidence;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get cancer gene list
     * @summary utilsCancerGeneListGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilsCancerGeneListGetUsingGET: async (version?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/utils/cancerGeneList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get cancer gene list in text file.
     * @summary utilsCancerGeneListTxtGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilsCancerGeneListTxtGetUsingGET: async (version?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/utils/cancerGeneList.txt`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CancerGenesApi - functional programming interface
 * @export
 */
export const CancerGenesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CancerGenesApiAxiosParamCreator(configuration);
  return {
    /**
     * Get All Actionable Variants.
     * @summary utilsAllActionableVariantsGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilsAllActionableVariantsGetUsingGET(
      version?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActionableGene>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.utilsAllActionableVariantsGetUsingGET(version, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get All Actionable Variants in text file.
     * @summary utilsAllActionableVariantsTxtGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilsAllActionableVariantsTxtGetUsingGET(
      version?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.utilsAllActionableVariantsTxtGetUsingGET(version, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get All Annotated Variants.
     * @summary utilsAllAnnotatedVariantsGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilsAllAnnotatedVariantsGetUsingGET(
      version?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnnotatedVariant>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.utilsAllAnnotatedVariantsGetUsingGET(version, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get All Annotated Variants in text file.
     * @summary utilsAllAnnotatedVariantsTxtGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilsAllAnnotatedVariantsTxtGetUsingGET(
      version?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.utilsAllAnnotatedVariantsTxtGetUsingGET(version, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get list of genes OncoKB curated
     * @summary utilsAllCuratedGenesGet
     * @param {string} [version] The data version
     * @param {boolean} [includeEvidence] Include gene summary and background
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilsAllCuratedGenesGetUsingGET(
      version?: string,
      includeEvidence?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CuratedGene>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.utilsAllCuratedGenesGetUsingGET(version, includeEvidence, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get list of genes OncoKB curated in text file.
     * @summary utilsAllCuratedGenesTxtGet
     * @param {string} [version] The data version
     * @param {boolean} [includeEvidence] Include gene summary and background
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilsAllCuratedGenesTxtGetUsingGET(
      version?: string,
      includeEvidence?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.utilsAllCuratedGenesTxtGetUsingGET(version, includeEvidence, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get cancer gene list
     * @summary utilsCancerGeneListGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilsCancerGeneListGetUsingGET(
      version?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CancerGene>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.utilsCancerGeneListGetUsingGET(version, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get cancer gene list in text file.
     * @summary utilsCancerGeneListTxtGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilsCancerGeneListTxtGetUsingGET(
      version?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.utilsCancerGeneListTxtGetUsingGET(version, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CancerGenesApi - factory interface
 * @export
 */
export const CancerGenesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CancerGenesApiFp(configuration);
  return {
    /**
     * Get All Actionable Variants.
     * @summary utilsAllActionableVariantsGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilsAllActionableVariantsGetUsingGET(version?: string, options?: any): AxiosPromise<Array<ActionableGene>> {
      return localVarFp.utilsAllActionableVariantsGetUsingGET(version, options).then(request => request(axios, basePath));
    },
    /**
     * Get All Actionable Variants in text file.
     * @summary utilsAllActionableVariantsTxtGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilsAllActionableVariantsTxtGetUsingGET(version?: string, options?: any): AxiosPromise<string> {
      return localVarFp.utilsAllActionableVariantsTxtGetUsingGET(version, options).then(request => request(axios, basePath));
    },
    /**
     * Get All Annotated Variants.
     * @summary utilsAllAnnotatedVariantsGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilsAllAnnotatedVariantsGetUsingGET(version?: string, options?: any): AxiosPromise<Array<AnnotatedVariant>> {
      return localVarFp.utilsAllAnnotatedVariantsGetUsingGET(version, options).then(request => request(axios, basePath));
    },
    /**
     * Get All Annotated Variants in text file.
     * @summary utilsAllAnnotatedVariantsTxtGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilsAllAnnotatedVariantsTxtGetUsingGET(version?: string, options?: any): AxiosPromise<string> {
      return localVarFp.utilsAllAnnotatedVariantsTxtGetUsingGET(version, options).then(request => request(axios, basePath));
    },
    /**
     * Get list of genes OncoKB curated
     * @summary utilsAllCuratedGenesGet
     * @param {string} [version] The data version
     * @param {boolean} [includeEvidence] Include gene summary and background
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilsAllCuratedGenesGetUsingGET(version?: string, includeEvidence?: boolean, options?: any): AxiosPromise<Array<CuratedGene>> {
      return localVarFp.utilsAllCuratedGenesGetUsingGET(version, includeEvidence, options).then(request => request(axios, basePath));
    },
    /**
     * Get list of genes OncoKB curated in text file.
     * @summary utilsAllCuratedGenesTxtGet
     * @param {string} [version] The data version
     * @param {boolean} [includeEvidence] Include gene summary and background
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilsAllCuratedGenesTxtGetUsingGET(version?: string, includeEvidence?: boolean, options?: any): AxiosPromise<string> {
      return localVarFp.utilsAllCuratedGenesTxtGetUsingGET(version, includeEvidence, options).then(request => request(axios, basePath));
    },
    /**
     * Get cancer gene list
     * @summary utilsCancerGeneListGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilsCancerGeneListGetUsingGET(version?: string, options?: any): AxiosPromise<Array<CancerGene>> {
      return localVarFp.utilsCancerGeneListGetUsingGET(version, options).then(request => request(axios, basePath));
    },
    /**
     * Get cancer gene list in text file.
     * @summary utilsCancerGeneListTxtGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilsCancerGeneListTxtGetUsingGET(version?: string, options?: any): AxiosPromise<string> {
      return localVarFp.utilsCancerGeneListTxtGetUsingGET(version, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * CancerGenesApi - object-oriented interface
 * @export
 * @class CancerGenesApi
 * @extends {BaseAPI}
 */
export class CancerGenesApi extends BaseAPI {
  /**
   * Get All Actionable Variants.
   * @summary utilsAllActionableVariantsGet
   * @param {string} [version] The data version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CancerGenesApi
   */
  public utilsAllActionableVariantsGetUsingGET(version?: string, options?: AxiosRequestConfig) {
    return CancerGenesApiFp(this.configuration)
      .utilsAllActionableVariantsGetUsingGET(version, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get All Actionable Variants in text file.
   * @summary utilsAllActionableVariantsTxtGet
   * @param {string} [version] The data version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CancerGenesApi
   */
  public utilsAllActionableVariantsTxtGetUsingGET(version?: string, options?: AxiosRequestConfig) {
    return CancerGenesApiFp(this.configuration)
      .utilsAllActionableVariantsTxtGetUsingGET(version, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get All Annotated Variants.
   * @summary utilsAllAnnotatedVariantsGet
   * @param {string} [version] The data version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CancerGenesApi
   */
  public utilsAllAnnotatedVariantsGetUsingGET(version?: string, options?: AxiosRequestConfig) {
    return CancerGenesApiFp(this.configuration)
      .utilsAllAnnotatedVariantsGetUsingGET(version, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get All Annotated Variants in text file.
   * @summary utilsAllAnnotatedVariantsTxtGet
   * @param {string} [version] The data version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CancerGenesApi
   */
  public utilsAllAnnotatedVariantsTxtGetUsingGET(version?: string, options?: AxiosRequestConfig) {
    return CancerGenesApiFp(this.configuration)
      .utilsAllAnnotatedVariantsTxtGetUsingGET(version, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get list of genes OncoKB curated
   * @summary utilsAllCuratedGenesGet
   * @param {string} [version] The data version
   * @param {boolean} [includeEvidence] Include gene summary and background
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CancerGenesApi
   */
  public utilsAllCuratedGenesGetUsingGET(version?: string, includeEvidence?: boolean, options?: AxiosRequestConfig) {
    return CancerGenesApiFp(this.configuration)
      .utilsAllCuratedGenesGetUsingGET(version, includeEvidence, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get list of genes OncoKB curated in text file.
   * @summary utilsAllCuratedGenesTxtGet
   * @param {string} [version] The data version
   * @param {boolean} [includeEvidence] Include gene summary and background
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CancerGenesApi
   */
  public utilsAllCuratedGenesTxtGetUsingGET(version?: string, includeEvidence?: boolean, options?: AxiosRequestConfig) {
    return CancerGenesApiFp(this.configuration)
      .utilsAllCuratedGenesTxtGetUsingGET(version, includeEvidence, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get cancer gene list
   * @summary utilsCancerGeneListGet
   * @param {string} [version] The data version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CancerGenesApi
   */
  public utilsCancerGeneListGetUsingGET(version?: string, options?: AxiosRequestConfig) {
    return CancerGenesApiFp(this.configuration)
      .utilsCancerGeneListGetUsingGET(version, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get cancer gene list in text file.
   * @summary utilsCancerGeneListTxtGet
   * @param {string} [version] The data version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CancerGenesApi
   */
  public utilsCancerGeneListTxtGetUsingGET(version?: string, options?: AxiosRequestConfig) {
    return CancerGenesApiFp(this.configuration)
      .utilsCancerGeneListTxtGetUsingGET(version, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ClassificationApi - axios parameter creator
 * @export
 */
export const ClassificationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get All OncoKB Variant Classification.
     * @summary classificationVariantsGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    classificationVariantsGetUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/classification/variants`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ClassificationApi - functional programming interface
 * @export
 */
export const ClassificationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ClassificationApiAxiosParamCreator(configuration);
  return {
    /**
     * Get All OncoKB Variant Classification.
     * @summary classificationVariantsGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async classificationVariantsGetUsingGET(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.classificationVariantsGetUsingGET(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ClassificationApi - factory interface
 * @export
 */
export const ClassificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ClassificationApiFp(configuration);
  return {
    /**
     * Get All OncoKB Variant Classification.
     * @summary classificationVariantsGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    classificationVariantsGetUsingGET(options?: any): AxiosPromise<Array<string>> {
      return localVarFp.classificationVariantsGetUsingGET(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * ClassificationApi - object-oriented interface
 * @export
 * @class ClassificationApi
 * @extends {BaseAPI}
 */
export class ClassificationApi extends BaseAPI {
  /**
   * Get All OncoKB Variant Classification.
   * @summary classificationVariantsGet
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClassificationApi
   */
  public classificationVariantsGetUsingGET(options?: AxiosRequestConfig) {
    return ClassificationApiFp(this.configuration)
      .classificationVariantsGetUsingGET(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DrugsApi - axios parameter creator
 * @export
 */
export const DrugsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get all curated drugs.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    drugsGetUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/drugs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search drugs.
     * @param {boolean} exactMatch Exactly Match
     * @param {string} [name] Drug Name
     * @param {string} [ncitCode] NCI Thesaurus Code
     * @param {string} [synonym] Drug Synonyms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    drugsLookupGetUsingGET: async (
      exactMatch: boolean,
      name?: string,
      ncitCode?: string,
      synonym?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'exactMatch' is not null or undefined
      assertParamExists('drugsLookupGetUsingGET', 'exactMatch', exactMatch);
      const localVarPath = `/drugs/lookup`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      if (ncitCode !== undefined) {
        localVarQueryParameter['ncitCode'] = ncitCode;
      }

      if (synonym !== undefined) {
        localVarQueryParameter['synonym'] = synonym;
      }

      if (exactMatch !== undefined) {
        localVarQueryParameter['exactMatch'] = exactMatch;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DrugsApi - functional programming interface
 * @export
 */
export const DrugsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DrugsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get all curated drugs.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async drugsGetUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Drug>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.drugsGetUsingGET(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Search drugs.
     * @param {boolean} exactMatch Exactly Match
     * @param {string} [name] Drug Name
     * @param {string} [ncitCode] NCI Thesaurus Code
     * @param {string} [synonym] Drug Synonyms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async drugsLookupGetUsingGET(
      exactMatch: boolean,
      name?: string,
      ncitCode?: string,
      synonym?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Drug>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.drugsLookupGetUsingGET(exactMatch, name, ncitCode, synonym, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DrugsApi - factory interface
 * @export
 */
export const DrugsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DrugsApiFp(configuration);
  return {
    /**
     *
     * @summary Get all curated drugs.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    drugsGetUsingGET(options?: any): AxiosPromise<Array<Drug>> {
      return localVarFp.drugsGetUsingGET(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Search drugs.
     * @param {boolean} exactMatch Exactly Match
     * @param {string} [name] Drug Name
     * @param {string} [ncitCode] NCI Thesaurus Code
     * @param {string} [synonym] Drug Synonyms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    drugsLookupGetUsingGET(
      exactMatch: boolean,
      name?: string,
      ncitCode?: string,
      synonym?: string,
      options?: any
    ): AxiosPromise<Array<Drug>> {
      return localVarFp.drugsLookupGetUsingGET(exactMatch, name, ncitCode, synonym, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * DrugsApi - object-oriented interface
 * @export
 * @class DrugsApi
 * @extends {BaseAPI}
 */
export class DrugsApi extends BaseAPI {
  /**
   *
   * @summary Get all curated drugs.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DrugsApi
   */
  public drugsGetUsingGET(options?: AxiosRequestConfig) {
    return DrugsApiFp(this.configuration)
      .drugsGetUsingGET(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Search drugs.
   * @param {boolean} exactMatch Exactly Match
   * @param {string} [name] Drug Name
   * @param {string} [ncitCode] NCI Thesaurus Code
   * @param {string} [synonym] Drug Synonyms
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DrugsApi
   */
  public drugsLookupGetUsingGET(exactMatch: boolean, name?: string, ncitCode?: string, synonym?: string, options?: AxiosRequestConfig) {
    return DrugsApiFp(this.configuration)
      .drugsLookupGetUsingGET(exactMatch, name, ncitCode, synonym, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * EvidencesApi - axios parameter creator
 * @export
 */
export const EvidencesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Search evidences. Multi-queries are supported.
     * @summary evidencesLookupGet
     * @param {number} [entrezGeneId] The entrez gene ID.
     * @param {string} [hugoSymbol] The gene symbol used in Human Genome Organisation.
     * @param {string} [variant] Variant name.
     * @param {string} [tumorType] Tumor type name. OncoTree code is supported.
     * @param {string} [consequence] Consequence. Possible value: feature_truncation, frameshift_variant, inframe_deletion, inframe_insertion, start_lost, missense_variant, splice_region_variant, stop_gained, synonymous_variant
     * @param {string} [proteinStart] Protein Start.
     * @param {string} [proteinEnd] Protein End.
     * @param {boolean} [highestLevelOnly] Only show highest level evidences
     * @param {string} [levelOfEvidence] Separate by comma. LEVEL_1, LEVEL_2A, LEVEL_2B, LEVEL_3A, LEVEL_3B, LEVEL_4, LEVEL_R1, LEVEL_R2, LEVEL_R3
     * @param {string} [evidenceTypes] Separate by comma. Evidence type includes GENE_SUMMARY, GENE_BACKGROUND, MUTATION_SUMMARY, ONCOGENIC, MUTATION_EFFECT, VUS, PROGNOSTIC_IMPLICATION, DIAGNOSTIC_IMPLICATION, TUMOR_TYPE_SUMMARY, DIAGNOSTIC_SUMMARY, PROGNOSTIC_SUMMARY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    evidencesLookupGetUsingGET: async (
      entrezGeneId?: number,
      hugoSymbol?: string,
      variant?: string,
      tumorType?: string,
      consequence?: string,
      proteinStart?: string,
      proteinEnd?: string,
      highestLevelOnly?: boolean,
      levelOfEvidence?: string,
      evidenceTypes?: string,
      fields?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/evidences/lookup`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (entrezGeneId !== undefined) {
        localVarQueryParameter['entrezGeneId'] = entrezGeneId;
      }

      if (hugoSymbol !== undefined) {
        localVarQueryParameter['hugoSymbol'] = hugoSymbol;
      }

      if (variant !== undefined) {
        localVarQueryParameter['variant'] = variant;
      }

      if (tumorType !== undefined) {
        localVarQueryParameter['tumorType'] = tumorType;
      }

      if (consequence !== undefined) {
        localVarQueryParameter['consequence'] = consequence;
      }

      if (proteinStart !== undefined) {
        localVarQueryParameter['proteinStart'] = proteinStart;
      }

      if (proteinEnd !== undefined) {
        localVarQueryParameter['proteinEnd'] = proteinEnd;
      }

      if (highestLevelOnly !== undefined) {
        localVarQueryParameter['highestLevelOnly'] = highestLevelOnly;
      }

      if (levelOfEvidence !== undefined) {
        localVarQueryParameter['levelOfEvidence'] = levelOfEvidence;
      }

      if (evidenceTypes !== undefined) {
        localVarQueryParameter['evidenceTypes'] = evidenceTypes;
      }

      if (fields !== undefined) {
        localVarQueryParameter['fields'] = fields;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Search evidences.
     * @summary evidencesLookupPost
     * @param {EvidenceQueries} body List of queries. Please see swagger.json for request body format. Please use JSON string.
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    evidencesLookupPostUsingPOST: async (
      body: EvidenceQueries,
      fields?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('evidencesLookupPostUsingPOST', 'body', body);
      const localVarPath = `/evidences/lookup`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (fields !== undefined) {
        localVarQueryParameter['fields'] = fields;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get specific evidence.
     * @summary evidencesUUIDGet
     * @param {string} uuid Unique identifier.
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    evidencesUUIDGetUsingGET: async (uuid: string, fields?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists('evidencesUUIDGetUsingGET', 'uuid', uuid);
      const localVarPath = `/evidences/{uuid}`.replace(`{${'uuid'}}`, encodeURIComponent(String(uuid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (fields !== undefined) {
        localVarQueryParameter['fields'] = fields;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get specific evidences.
     * @summary evidencesUUIDsGet
     * @param {Array<string>} uuids Unique identifier list.
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    evidencesUUIDsGetUsingPOST: async (uuids: Array<string>, fields?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uuids' is not null or undefined
      assertParamExists('evidencesUUIDsGetUsingPOST', 'uuids', uuids);
      const localVarPath = `/evidences`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (fields !== undefined) {
        localVarQueryParameter['fields'] = fields;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(uuids, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EvidencesApi - functional programming interface
 * @export
 */
export const EvidencesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EvidencesApiAxiosParamCreator(configuration);
  return {
    /**
     * Search evidences. Multi-queries are supported.
     * @summary evidencesLookupGet
     * @param {number} [entrezGeneId] The entrez gene ID.
     * @param {string} [hugoSymbol] The gene symbol used in Human Genome Organisation.
     * @param {string} [variant] Variant name.
     * @param {string} [tumorType] Tumor type name. OncoTree code is supported.
     * @param {string} [consequence] Consequence. Possible value: feature_truncation, frameshift_variant, inframe_deletion, inframe_insertion, start_lost, missense_variant, splice_region_variant, stop_gained, synonymous_variant
     * @param {string} [proteinStart] Protein Start.
     * @param {string} [proteinEnd] Protein End.
     * @param {boolean} [highestLevelOnly] Only show highest level evidences
     * @param {string} [levelOfEvidence] Separate by comma. LEVEL_1, LEVEL_2A, LEVEL_2B, LEVEL_3A, LEVEL_3B, LEVEL_4, LEVEL_R1, LEVEL_R2, LEVEL_R3
     * @param {string} [evidenceTypes] Separate by comma. Evidence type includes GENE_SUMMARY, GENE_BACKGROUND, MUTATION_SUMMARY, ONCOGENIC, MUTATION_EFFECT, VUS, PROGNOSTIC_IMPLICATION, DIAGNOSTIC_IMPLICATION, TUMOR_TYPE_SUMMARY, DIAGNOSTIC_SUMMARY, PROGNOSTIC_SUMMARY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async evidencesLookupGetUsingGET(
      entrezGeneId?: number,
      hugoSymbol?: string,
      variant?: string,
      tumorType?: string,
      consequence?: string,
      proteinStart?: string,
      proteinEnd?: string,
      highestLevelOnly?: boolean,
      levelOfEvidence?: string,
      evidenceTypes?: string,
      fields?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Evidence>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.evidencesLookupGetUsingGET(
        entrezGeneId,
        hugoSymbol,
        variant,
        tumorType,
        consequence,
        proteinStart,
        proteinEnd,
        highestLevelOnly,
        levelOfEvidence,
        evidenceTypes,
        fields,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Search evidences.
     * @summary evidencesLookupPost
     * @param {EvidenceQueries} body List of queries. Please see swagger.json for request body format. Please use JSON string.
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async evidencesLookupPostUsingPOST(
      body: EvidenceQueries,
      fields?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EvidenceQueryRes>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.evidencesLookupPostUsingPOST(body, fields, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get specific evidence.
     * @summary evidencesUUIDGet
     * @param {string} uuid Unique identifier.
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async evidencesUUIDGetUsingGET(
      uuid: string,
      fields?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Evidence>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.evidencesUUIDGetUsingGET(uuid, fields, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get specific evidences.
     * @summary evidencesUUIDsGet
     * @param {Array<string>} uuids Unique identifier list.
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async evidencesUUIDsGetUsingPOST(
      uuids: Array<string>,
      fields?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Evidence>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.evidencesUUIDsGetUsingPOST(uuids, fields, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * EvidencesApi - factory interface
 * @export
 */
export const EvidencesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = EvidencesApiFp(configuration);
  return {
    /**
     * Search evidences. Multi-queries are supported.
     * @summary evidencesLookupGet
     * @param {number} [entrezGeneId] The entrez gene ID.
     * @param {string} [hugoSymbol] The gene symbol used in Human Genome Organisation.
     * @param {string} [variant] Variant name.
     * @param {string} [tumorType] Tumor type name. OncoTree code is supported.
     * @param {string} [consequence] Consequence. Possible value: feature_truncation, frameshift_variant, inframe_deletion, inframe_insertion, start_lost, missense_variant, splice_region_variant, stop_gained, synonymous_variant
     * @param {string} [proteinStart] Protein Start.
     * @param {string} [proteinEnd] Protein End.
     * @param {boolean} [highestLevelOnly] Only show highest level evidences
     * @param {string} [levelOfEvidence] Separate by comma. LEVEL_1, LEVEL_2A, LEVEL_2B, LEVEL_3A, LEVEL_3B, LEVEL_4, LEVEL_R1, LEVEL_R2, LEVEL_R3
     * @param {string} [evidenceTypes] Separate by comma. Evidence type includes GENE_SUMMARY, GENE_BACKGROUND, MUTATION_SUMMARY, ONCOGENIC, MUTATION_EFFECT, VUS, PROGNOSTIC_IMPLICATION, DIAGNOSTIC_IMPLICATION, TUMOR_TYPE_SUMMARY, DIAGNOSTIC_SUMMARY, PROGNOSTIC_SUMMARY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    evidencesLookupGetUsingGET(
      entrezGeneId?: number,
      hugoSymbol?: string,
      variant?: string,
      tumorType?: string,
      consequence?: string,
      proteinStart?: string,
      proteinEnd?: string,
      highestLevelOnly?: boolean,
      levelOfEvidence?: string,
      evidenceTypes?: string,
      fields?: string,
      options?: any
    ): AxiosPromise<Array<Evidence>> {
      return localVarFp
        .evidencesLookupGetUsingGET(
          entrezGeneId,
          hugoSymbol,
          variant,
          tumorType,
          consequence,
          proteinStart,
          proteinEnd,
          highestLevelOnly,
          levelOfEvidence,
          evidenceTypes,
          fields,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Search evidences.
     * @summary evidencesLookupPost
     * @param {EvidenceQueries} body List of queries. Please see swagger.json for request body format. Please use JSON string.
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    evidencesLookupPostUsingPOST(body: EvidenceQueries, fields?: string, options?: any): AxiosPromise<Array<EvidenceQueryRes>> {
      return localVarFp.evidencesLookupPostUsingPOST(body, fields, options).then(request => request(axios, basePath));
    },
    /**
     * Get specific evidence.
     * @summary evidencesUUIDGet
     * @param {string} uuid Unique identifier.
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    evidencesUUIDGetUsingGET(uuid: string, fields?: string, options?: any): AxiosPromise<Evidence> {
      return localVarFp.evidencesUUIDGetUsingGET(uuid, fields, options).then(request => request(axios, basePath));
    },
    /**
     * Get specific evidences.
     * @summary evidencesUUIDsGet
     * @param {Array<string>} uuids Unique identifier list.
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    evidencesUUIDsGetUsingPOST(uuids: Array<string>, fields?: string, options?: any): AxiosPromise<Evidence> {
      return localVarFp.evidencesUUIDsGetUsingPOST(uuids, fields, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * EvidencesApi - object-oriented interface
 * @export
 * @class EvidencesApi
 * @extends {BaseAPI}
 */
export class EvidencesApi extends BaseAPI {
  /**
   * Search evidences. Multi-queries are supported.
   * @summary evidencesLookupGet
   * @param {number} [entrezGeneId] The entrez gene ID.
   * @param {string} [hugoSymbol] The gene symbol used in Human Genome Organisation.
   * @param {string} [variant] Variant name.
   * @param {string} [tumorType] Tumor type name. OncoTree code is supported.
   * @param {string} [consequence] Consequence. Possible value: feature_truncation, frameshift_variant, inframe_deletion, inframe_insertion, start_lost, missense_variant, splice_region_variant, stop_gained, synonymous_variant
   * @param {string} [proteinStart] Protein Start.
   * @param {string} [proteinEnd] Protein End.
   * @param {boolean} [highestLevelOnly] Only show highest level evidences
   * @param {string} [levelOfEvidence] Separate by comma. LEVEL_1, LEVEL_2A, LEVEL_2B, LEVEL_3A, LEVEL_3B, LEVEL_4, LEVEL_R1, LEVEL_R2, LEVEL_R3
   * @param {string} [evidenceTypes] Separate by comma. Evidence type includes GENE_SUMMARY, GENE_BACKGROUND, MUTATION_SUMMARY, ONCOGENIC, MUTATION_EFFECT, VUS, PROGNOSTIC_IMPLICATION, DIAGNOSTIC_IMPLICATION, TUMOR_TYPE_SUMMARY, DIAGNOSTIC_SUMMARY, PROGNOSTIC_SUMMARY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_SENSITIVITY, STANDARD_THERAPEUTIC_IMPLICATIONS_FOR_DRUG_RESISTANCE, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_SENSITIVITY, INVESTIGATIONAL_THERAPEUTIC_IMPLICATIONS_DRUG_RESISTANCE
   * @param {string} [fields] The fields to be returned.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EvidencesApi
   */
  public evidencesLookupGetUsingGET(
    entrezGeneId?: number,
    hugoSymbol?: string,
    variant?: string,
    tumorType?: string,
    consequence?: string,
    proteinStart?: string,
    proteinEnd?: string,
    highestLevelOnly?: boolean,
    levelOfEvidence?: string,
    evidenceTypes?: string,
    fields?: string,
    options?: AxiosRequestConfig
  ) {
    return EvidencesApiFp(this.configuration)
      .evidencesLookupGetUsingGET(
        entrezGeneId,
        hugoSymbol,
        variant,
        tumorType,
        consequence,
        proteinStart,
        proteinEnd,
        highestLevelOnly,
        levelOfEvidence,
        evidenceTypes,
        fields,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Search evidences.
   * @summary evidencesLookupPost
   * @param {EvidenceQueries} body List of queries. Please see swagger.json for request body format. Please use JSON string.
   * @param {string} [fields] The fields to be returned.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EvidencesApi
   */
  public evidencesLookupPostUsingPOST(body: EvidenceQueries, fields?: string, options?: AxiosRequestConfig) {
    return EvidencesApiFp(this.configuration)
      .evidencesLookupPostUsingPOST(body, fields, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get specific evidence.
   * @summary evidencesUUIDGet
   * @param {string} uuid Unique identifier.
   * @param {string} [fields] The fields to be returned.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EvidencesApi
   */
  public evidencesUUIDGetUsingGET(uuid: string, fields?: string, options?: AxiosRequestConfig) {
    return EvidencesApiFp(this.configuration)
      .evidencesUUIDGetUsingGET(uuid, fields, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get specific evidences.
   * @summary evidencesUUIDsGet
   * @param {Array<string>} uuids Unique identifier list.
   * @param {string} [fields] The fields to be returned.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EvidencesApi
   */
  public evidencesUUIDsGetUsingPOST(uuids: Array<string>, fields?: string, options?: AxiosRequestConfig) {
    return EvidencesApiFp(this.configuration)
      .evidencesUUIDsGetUsingPOST(uuids, fields, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * GenesApi - axios parameter creator
 * @export
 */
export const GenesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get list of evidences for specific gene.
     * @summary genesEntrezGeneIdEvidencesGet
     * @param {number} entrezGeneId The entrez gene ID.
     * @param {string} [evidenceTypes] Separate by comma. Evidence type includes GENE_SUMMARY, GENE_BACKGROUND
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genesEntrezGeneIdEvidencesGetUsingGET: async (
      entrezGeneId: number,
      evidenceTypes?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'entrezGeneId' is not null or undefined
      assertParamExists('genesEntrezGeneIdEvidencesGetUsingGET', 'entrezGeneId', entrezGeneId);
      const localVarPath = `/genes/{entrezGeneId}/evidences`.replace(`{${'entrezGeneId'}}`, encodeURIComponent(String(entrezGeneId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (evidenceTypes !== undefined) {
        localVarQueryParameter['evidenceTypes'] = evidenceTypes;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get specific gene information.
     * @summary genesEntrezGeneIdGet
     * @param {number} entrezGeneId The entrez gene ID.
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genesEntrezGeneIdGetUsingGET: async (entrezGeneId: number, fields?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'entrezGeneId' is not null or undefined
      assertParamExists('genesEntrezGeneIdGetUsingGET', 'entrezGeneId', entrezGeneId);
      const localVarPath = `/genes/{entrezGeneId}`.replace(`{${'entrezGeneId'}}`, encodeURIComponent(String(entrezGeneId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (fields !== undefined) {
        localVarQueryParameter['fields'] = fields;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get list of variants for specific gene.
     * @summary genesEntrezGeneIdVariantsGet
     * @param {number} entrezGeneId The entrez gene ID.
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genesEntrezGeneIdVariantsGetUsingGET: async (
      entrezGeneId: number,
      fields?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'entrezGeneId' is not null or undefined
      assertParamExists('genesEntrezGeneIdVariantsGetUsingGET', 'entrezGeneId', entrezGeneId);
      const localVarPath = `/genes/{entrezGeneId}/variants`.replace(`{${'entrezGeneId'}}`, encodeURIComponent(String(entrezGeneId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (fields !== undefined) {
        localVarQueryParameter['fields'] = fields;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get list of currently curated genes.
     * @summary genesGet
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genesGetUsingGET: async (fields?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/genes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (fields !== undefined) {
        localVarQueryParameter['fields'] = fields;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Search gene.
     * @summary genesLookupGet
     * @param {string} [hugoSymbol] The gene symbol used in Human Genome Organisation. (Deprecated, use query instead)
     * @param {number} [entrezGeneId] The entrez gene ID. (Deprecated, use query instead)
     * @param {string} [query] The search query, it could be hugoSymbol or entrezGeneId.
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genesLookupGetUsingGET: async (
      hugoSymbol?: string,
      entrezGeneId?: number,
      query?: string,
      fields?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/genes/lookup`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (hugoSymbol !== undefined) {
        localVarQueryParameter['hugoSymbol'] = hugoSymbol;
      }

      if (entrezGeneId !== undefined) {
        localVarQueryParameter['entrezGeneId'] = entrezGeneId;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (fields !== undefined) {
        localVarQueryParameter['fields'] = fields;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GenesApi - functional programming interface
 * @export
 */
export const GenesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GenesApiAxiosParamCreator(configuration);
  return {
    /**
     * Get list of evidences for specific gene.
     * @summary genesEntrezGeneIdEvidencesGet
     * @param {number} entrezGeneId The entrez gene ID.
     * @param {string} [evidenceTypes] Separate by comma. Evidence type includes GENE_SUMMARY, GENE_BACKGROUND
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async genesEntrezGeneIdEvidencesGetUsingGET(
      entrezGeneId: number,
      evidenceTypes?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneEvidence>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.genesEntrezGeneIdEvidencesGetUsingGET(entrezGeneId, evidenceTypes, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get specific gene information.
     * @summary genesEntrezGeneIdGet
     * @param {number} entrezGeneId The entrez gene ID.
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async genesEntrezGeneIdGetUsingGET(
      entrezGeneId: number,
      fields?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Gene>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.genesEntrezGeneIdGetUsingGET(entrezGeneId, fields, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get list of variants for specific gene.
     * @summary genesEntrezGeneIdVariantsGet
     * @param {number} entrezGeneId The entrez gene ID.
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async genesEntrezGeneIdVariantsGetUsingGET(
      entrezGeneId: number,
      fields?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Alteration>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.genesEntrezGeneIdVariantsGetUsingGET(entrezGeneId, fields, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get list of currently curated genes.
     * @summary genesGet
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async genesGetUsingGET(
      fields?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Gene>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.genesGetUsingGET(fields, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Search gene.
     * @summary genesLookupGet
     * @param {string} [hugoSymbol] The gene symbol used in Human Genome Organisation. (Deprecated, use query instead)
     * @param {number} [entrezGeneId] The entrez gene ID. (Deprecated, use query instead)
     * @param {string} [query] The search query, it could be hugoSymbol or entrezGeneId.
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async genesLookupGetUsingGET(
      hugoSymbol?: string,
      entrezGeneId?: number,
      query?: string,
      fields?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Gene>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.genesLookupGetUsingGET(hugoSymbol, entrezGeneId, query, fields, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * GenesApi - factory interface
 * @export
 */
export const GenesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = GenesApiFp(configuration);
  return {
    /**
     * Get list of evidences for specific gene.
     * @summary genesEntrezGeneIdEvidencesGet
     * @param {number} entrezGeneId The entrez gene ID.
     * @param {string} [evidenceTypes] Separate by comma. Evidence type includes GENE_SUMMARY, GENE_BACKGROUND
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genesEntrezGeneIdEvidencesGetUsingGET(entrezGeneId: number, evidenceTypes?: string, options?: any): AxiosPromise<Array<GeneEvidence>> {
      return localVarFp
        .genesEntrezGeneIdEvidencesGetUsingGET(entrezGeneId, evidenceTypes, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Get specific gene information.
     * @summary genesEntrezGeneIdGet
     * @param {number} entrezGeneId The entrez gene ID.
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genesEntrezGeneIdGetUsingGET(entrezGeneId: number, fields?: string, options?: any): AxiosPromise<Gene> {
      return localVarFp.genesEntrezGeneIdGetUsingGET(entrezGeneId, fields, options).then(request => request(axios, basePath));
    },
    /**
     * Get list of variants for specific gene.
     * @summary genesEntrezGeneIdVariantsGet
     * @param {number} entrezGeneId The entrez gene ID.
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genesEntrezGeneIdVariantsGetUsingGET(entrezGeneId: number, fields?: string, options?: any): AxiosPromise<Array<Alteration>> {
      return localVarFp.genesEntrezGeneIdVariantsGetUsingGET(entrezGeneId, fields, options).then(request => request(axios, basePath));
    },
    /**
     * Get list of currently curated genes.
     * @summary genesGet
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genesGetUsingGET(fields?: string, options?: any): AxiosPromise<Array<Gene>> {
      return localVarFp.genesGetUsingGET(fields, options).then(request => request(axios, basePath));
    },
    /**
     * Search gene.
     * @summary genesLookupGet
     * @param {string} [hugoSymbol] The gene symbol used in Human Genome Organisation. (Deprecated, use query instead)
     * @param {number} [entrezGeneId] The entrez gene ID. (Deprecated, use query instead)
     * @param {string} [query] The search query, it could be hugoSymbol or entrezGeneId.
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genesLookupGetUsingGET(
      hugoSymbol?: string,
      entrezGeneId?: number,
      query?: string,
      fields?: string,
      options?: any
    ): AxiosPromise<Array<Gene>> {
      return localVarFp.genesLookupGetUsingGET(hugoSymbol, entrezGeneId, query, fields, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * GenesApi - object-oriented interface
 * @export
 * @class GenesApi
 * @extends {BaseAPI}
 */
export class GenesApi extends BaseAPI {
  /**
   * Get list of evidences for specific gene.
   * @summary genesEntrezGeneIdEvidencesGet
   * @param {number} entrezGeneId The entrez gene ID.
   * @param {string} [evidenceTypes] Separate by comma. Evidence type includes GENE_SUMMARY, GENE_BACKGROUND
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GenesApi
   */
  public genesEntrezGeneIdEvidencesGetUsingGET(entrezGeneId: number, evidenceTypes?: string, options?: AxiosRequestConfig) {
    return GenesApiFp(this.configuration)
      .genesEntrezGeneIdEvidencesGetUsingGET(entrezGeneId, evidenceTypes, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get specific gene information.
   * @summary genesEntrezGeneIdGet
   * @param {number} entrezGeneId The entrez gene ID.
   * @param {string} [fields] The fields to be returned.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GenesApi
   */
  public genesEntrezGeneIdGetUsingGET(entrezGeneId: number, fields?: string, options?: AxiosRequestConfig) {
    return GenesApiFp(this.configuration)
      .genesEntrezGeneIdGetUsingGET(entrezGeneId, fields, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get list of variants for specific gene.
   * @summary genesEntrezGeneIdVariantsGet
   * @param {number} entrezGeneId The entrez gene ID.
   * @param {string} [fields] The fields to be returned.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GenesApi
   */
  public genesEntrezGeneIdVariantsGetUsingGET(entrezGeneId: number, fields?: string, options?: AxiosRequestConfig) {
    return GenesApiFp(this.configuration)
      .genesEntrezGeneIdVariantsGetUsingGET(entrezGeneId, fields, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get list of currently curated genes.
   * @summary genesGet
   * @param {string} [fields] The fields to be returned.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GenesApi
   */
  public genesGetUsingGET(fields?: string, options?: AxiosRequestConfig) {
    return GenesApiFp(this.configuration)
      .genesGetUsingGET(fields, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Search gene.
   * @summary genesLookupGet
   * @param {string} [hugoSymbol] The gene symbol used in Human Genome Organisation. (Deprecated, use query instead)
   * @param {number} [entrezGeneId] The entrez gene ID. (Deprecated, use query instead)
   * @param {string} [query] The search query, it could be hugoSymbol or entrezGeneId.
   * @param {string} [fields] The fields to be returned.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GenesApi
   */
  public genesLookupGetUsingGET(hugoSymbol?: string, entrezGeneId?: number, query?: string, fields?: string, options?: AxiosRequestConfig) {
    return GenesApiFp(this.configuration)
      .genesLookupGetUsingGET(hugoSymbol, entrezGeneId, query, fields, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * GenesetsApi - axios parameter creator
 * @export
 */
export const GenesetsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get list of currently curated genesets.
     * @summary genesetsGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genesetsGetUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/genesets`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Find geneset by uuid
     * @summary genesetsUuidGet
     * @param {string} uuid Geneset UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genesetsUuidGetUsingGET: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists('genesetsUuidGetUsingGET', 'uuid', uuid);
      const localVarPath = `/genesets/{uuid}`.replace(`{${'uuid'}}`, encodeURIComponent(String(uuid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GenesetsApi - functional programming interface
 * @export
 */
export const GenesetsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GenesetsApiAxiosParamCreator(configuration);
  return {
    /**
     * Get list of currently curated genesets.
     * @summary genesetsGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async genesetsGetUsingGET(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Geneset>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.genesetsGetUsingGET(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Find geneset by uuid
     * @summary genesetsUuidGet
     * @param {string} uuid Geneset UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async genesetsUuidGetUsingGET(
      uuid: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Geneset>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.genesetsUuidGetUsingGET(uuid, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * GenesetsApi - factory interface
 * @export
 */
export const GenesetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = GenesetsApiFp(configuration);
  return {
    /**
     * Get list of currently curated genesets.
     * @summary genesetsGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genesetsGetUsingGET(options?: any): AxiosPromise<Array<Geneset>> {
      return localVarFp.genesetsGetUsingGET(options).then(request => request(axios, basePath));
    },
    /**
     * Find geneset by uuid
     * @summary genesetsUuidGet
     * @param {string} uuid Geneset UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genesetsUuidGetUsingGET(uuid: string, options?: any): AxiosPromise<Geneset> {
      return localVarFp.genesetsUuidGetUsingGET(uuid, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * GenesetsApi - object-oriented interface
 * @export
 * @class GenesetsApi
 * @extends {BaseAPI}
 */
export class GenesetsApi extends BaseAPI {
  /**
   * Get list of currently curated genesets.
   * @summary genesetsGet
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GenesetsApi
   */
  public genesetsGetUsingGET(options?: AxiosRequestConfig) {
    return GenesetsApiFp(this.configuration)
      .genesetsGetUsingGET(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Find geneset by uuid
   * @summary genesetsUuidGet
   * @param {string} uuid Geneset UUID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GenesetsApi
   */
  public genesetsUuidGetUsingGET(uuid: string, options?: AxiosRequestConfig) {
    return GenesetsApiFp(this.configuration)
      .genesetsUuidGetUsingGET(uuid, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * InfoApi - axios parameter creator
 * @export
 */
export const InfoApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary infoGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    infoGetUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/info`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InfoApi - functional programming interface
 * @export
 */
export const InfoApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = InfoApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary infoGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async infoGetUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OncoKBInfo>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.infoGetUsingGET(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * InfoApi - factory interface
 * @export
 */
export const InfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = InfoApiFp(configuration);
  return {
    /**
     *
     * @summary infoGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    infoGetUsingGET(options?: any): AxiosPromise<OncoKBInfo> {
      return localVarFp.infoGetUsingGET(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * InfoApi - object-oriented interface
 * @export
 * @class InfoApi
 * @extends {BaseAPI}
 */
export class InfoApi extends BaseAPI {
  /**
   *
   * @summary infoGet
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InfoApi
   */
  public infoGetUsingGET(options?: AxiosRequestConfig) {
    return InfoApiFp(this.configuration)
      .infoGetUsingGET(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * LevelsApi - axios parameter creator
 * @export
 */
export const LevelsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get all diagnostic levels.
     * @summary levelsDiagnosticGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    levelsDiagnosticGetUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/levels/diagnostic`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all levels.
     * @summary levelsGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    levelsGetUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/levels`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all prognostic levels.
     * @summary levelsPrognosticGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    levelsPrognosticGetUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/levels/prognostic`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all resistance levels.
     * @summary levelsResistanceGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    levelsResistanceGetUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/levels/resistance`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all sensitive levels.
     * @summary levelsSensitiveGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    levelsSensitiveGetUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/levels/sensitive`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LevelsApi - functional programming interface
 * @export
 */
export const LevelsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LevelsApiAxiosParamCreator(configuration);
  return {
    /**
     * Get all diagnostic levels.
     * @summary levelsDiagnosticGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async levelsDiagnosticGetUsingGET(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.levelsDiagnosticGetUsingGET(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get all levels.
     * @summary levelsGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async levelsGetUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.levelsGetUsingGET(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get all prognostic levels.
     * @summary levelsPrognosticGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async levelsPrognosticGetUsingGET(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.levelsPrognosticGetUsingGET(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get all resistance levels.
     * @summary levelsResistanceGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async levelsResistanceGetUsingGET(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.levelsResistanceGetUsingGET(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get all sensitive levels.
     * @summary levelsSensitiveGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async levelsSensitiveGetUsingGET(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.levelsSensitiveGetUsingGET(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * LevelsApi - factory interface
 * @export
 */
export const LevelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = LevelsApiFp(configuration);
  return {
    /**
     * Get all diagnostic levels.
     * @summary levelsDiagnosticGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    levelsDiagnosticGetUsingGET(options?: any): AxiosPromise<object> {
      return localVarFp.levelsDiagnosticGetUsingGET(options).then(request => request(axios, basePath));
    },
    /**
     * Get all levels.
     * @summary levelsGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    levelsGetUsingGET(options?: any): AxiosPromise<object> {
      return localVarFp.levelsGetUsingGET(options).then(request => request(axios, basePath));
    },
    /**
     * Get all prognostic levels.
     * @summary levelsPrognosticGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    levelsPrognosticGetUsingGET(options?: any): AxiosPromise<object> {
      return localVarFp.levelsPrognosticGetUsingGET(options).then(request => request(axios, basePath));
    },
    /**
     * Get all resistance levels.
     * @summary levelsResistanceGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    levelsResistanceGetUsingGET(options?: any): AxiosPromise<object> {
      return localVarFp.levelsResistanceGetUsingGET(options).then(request => request(axios, basePath));
    },
    /**
     * Get all sensitive levels.
     * @summary levelsSensitiveGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    levelsSensitiveGetUsingGET(options?: any): AxiosPromise<object> {
      return localVarFp.levelsSensitiveGetUsingGET(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * LevelsApi - object-oriented interface
 * @export
 * @class LevelsApi
 * @extends {BaseAPI}
 */
export class LevelsApi extends BaseAPI {
  /**
   * Get all diagnostic levels.
   * @summary levelsDiagnosticGet
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LevelsApi
   */
  public levelsDiagnosticGetUsingGET(options?: AxiosRequestConfig) {
    return LevelsApiFp(this.configuration)
      .levelsDiagnosticGetUsingGET(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get all levels.
   * @summary levelsGet
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LevelsApi
   */
  public levelsGetUsingGET(options?: AxiosRequestConfig) {
    return LevelsApiFp(this.configuration)
      .levelsGetUsingGET(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get all prognostic levels.
   * @summary levelsPrognosticGet
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LevelsApi
   */
  public levelsPrognosticGetUsingGET(options?: AxiosRequestConfig) {
    return LevelsApiFp(this.configuration)
      .levelsPrognosticGetUsingGET(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get all resistance levels.
   * @summary levelsResistanceGet
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LevelsApi
   */
  public levelsResistanceGetUsingGET(options?: AxiosRequestConfig) {
    return LevelsApiFp(this.configuration)
      .levelsResistanceGetUsingGET(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get all sensitive levels.
   * @summary levelsSensitiveGet
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LevelsApi
   */
  public levelsSensitiveGetUsingGET(options?: AxiosRequestConfig) {
    return LevelsApiFp(this.configuration)
      .levelsSensitiveGetUsingGET(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * General search for possible combinations.
     * @summary searchGet
     * @param {string} [id] The query ID
     * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
     * @param {string} [hugoSymbol] The gene symbol used in Human Genome Organisation.
     * @param {number} [entrezGeneId] The entrez gene ID.
     * @param {string} [variant] Variant name.
     * @param {string} [variantType] Variant type.
     * @param {'DELETION' | 'TRANSLOCATION' | 'DUPLICATION' | 'INSERTION' | 'INVERSION' | 'FUSION' | 'UNKNOWN'} [svType] Structural Variant Type.
     * @param {string} [consequence] Consequence
     * @param {number} [proteinStart] Protein Start
     * @param {number} [proteinEnd] Protein End
     * @param {string} [tumorType] Tumor type name. OncoTree code is supported.
     * @param {string} [levels] Level of evidences.
     * @param {boolean} [highestLevelOnly] Only show treatments of highest level
     * @param {string} [evidenceType] Evidence type.
     * @param {string} [hgvs] HGVS varaint. Its priority is higher than entrezGeneId/hugoSymbol + variant combination
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchGetUsingGET: async (
      id?: string,
      referenceGenome?: string,
      hugoSymbol?: string,
      entrezGeneId?: number,
      variant?: string,
      variantType?: string,
      svType?: 'DELETION' | 'TRANSLOCATION' | 'DUPLICATION' | 'INSERTION' | 'INVERSION' | 'FUSION' | 'UNKNOWN',
      consequence?: string,
      proteinStart?: number,
      proteinEnd?: number,
      tumorType?: string,
      levels?: string,
      highestLevelOnly?: boolean,
      evidenceType?: string,
      hgvs?: string,
      fields?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      if (referenceGenome !== undefined) {
        localVarQueryParameter['referenceGenome'] = referenceGenome;
      }

      if (hugoSymbol !== undefined) {
        localVarQueryParameter['hugoSymbol'] = hugoSymbol;
      }

      if (entrezGeneId !== undefined) {
        localVarQueryParameter['entrezGeneId'] = entrezGeneId;
      }

      if (variant !== undefined) {
        localVarQueryParameter['variant'] = variant;
      }

      if (variantType !== undefined) {
        localVarQueryParameter['variantType'] = variantType;
      }

      if (svType !== undefined) {
        localVarQueryParameter['svType'] = svType;
      }

      if (consequence !== undefined) {
        localVarQueryParameter['consequence'] = consequence;
      }

      if (proteinStart !== undefined) {
        localVarQueryParameter['proteinStart'] = proteinStart;
      }

      if (proteinEnd !== undefined) {
        localVarQueryParameter['proteinEnd'] = proteinEnd;
      }

      if (tumorType !== undefined) {
        localVarQueryParameter['tumorType'] = tumorType;
      }

      if (levels !== undefined) {
        localVarQueryParameter['levels'] = levels;
      }

      if (highestLevelOnly !== undefined) {
        localVarQueryParameter['highestLevelOnly'] = highestLevelOnly;
      }

      if (evidenceType !== undefined) {
        localVarQueryParameter['evidenceType'] = evidenceType;
      }

      if (hgvs !== undefined) {
        localVarQueryParameter['hgvs'] = hgvs;
      }

      if (fields !== undefined) {
        localVarQueryParameter['fields'] = fields;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * General search for possible combinations.
     * @summary searchPost
     * @param {EvidenceQueries} body List of queries. Please see swagger.json for request body format.
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchPostUsingPOST: async (body: EvidenceQueries, fields?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('searchPostUsingPOST', 'body', body);
      const localVarPath = `/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (fields !== undefined) {
        localVarQueryParameter['fields'] = fields;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration);
  return {
    /**
     * General search for possible combinations.
     * @summary searchGet
     * @param {string} [id] The query ID
     * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
     * @param {string} [hugoSymbol] The gene symbol used in Human Genome Organisation.
     * @param {number} [entrezGeneId] The entrez gene ID.
     * @param {string} [variant] Variant name.
     * @param {string} [variantType] Variant type.
     * @param {'DELETION' | 'TRANSLOCATION' | 'DUPLICATION' | 'INSERTION' | 'INVERSION' | 'FUSION' | 'UNKNOWN'} [svType] Structural Variant Type.
     * @param {string} [consequence] Consequence
     * @param {number} [proteinStart] Protein Start
     * @param {number} [proteinEnd] Protein End
     * @param {string} [tumorType] Tumor type name. OncoTree code is supported.
     * @param {string} [levels] Level of evidences.
     * @param {boolean} [highestLevelOnly] Only show treatments of highest level
     * @param {string} [evidenceType] Evidence type.
     * @param {string} [hgvs] HGVS varaint. Its priority is higher than entrezGeneId/hugoSymbol + variant combination
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchGetUsingGET(
      id?: string,
      referenceGenome?: string,
      hugoSymbol?: string,
      entrezGeneId?: number,
      variant?: string,
      variantType?: string,
      svType?: 'DELETION' | 'TRANSLOCATION' | 'DUPLICATION' | 'INSERTION' | 'INVERSION' | 'FUSION' | 'UNKNOWN',
      consequence?: string,
      proteinStart?: number,
      proteinEnd?: number,
      tumorType?: string,
      levels?: string,
      highestLevelOnly?: boolean,
      evidenceType?: string,
      hgvs?: string,
      fields?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndicatorQueryResp>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchGetUsingGET(
        id,
        referenceGenome,
        hugoSymbol,
        entrezGeneId,
        variant,
        variantType,
        svType,
        consequence,
        proteinStart,
        proteinEnd,
        tumorType,
        levels,
        highestLevelOnly,
        evidenceType,
        hgvs,
        fields,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * General search for possible combinations.
     * @summary searchPost
     * @param {EvidenceQueries} body List of queries. Please see swagger.json for request body format.
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchPostUsingPOST(
      body: EvidenceQueries,
      fields?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IndicatorQueryResp>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchPostUsingPOST(body, fields, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SearchApiFp(configuration);
  return {
    /**
     * General search for possible combinations.
     * @summary searchGet
     * @param {string} [id] The query ID
     * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
     * @param {string} [hugoSymbol] The gene symbol used in Human Genome Organisation.
     * @param {number} [entrezGeneId] The entrez gene ID.
     * @param {string} [variant] Variant name.
     * @param {string} [variantType] Variant type.
     * @param {'DELETION' | 'TRANSLOCATION' | 'DUPLICATION' | 'INSERTION' | 'INVERSION' | 'FUSION' | 'UNKNOWN'} [svType] Structural Variant Type.
     * @param {string} [consequence] Consequence
     * @param {number} [proteinStart] Protein Start
     * @param {number} [proteinEnd] Protein End
     * @param {string} [tumorType] Tumor type name. OncoTree code is supported.
     * @param {string} [levels] Level of evidences.
     * @param {boolean} [highestLevelOnly] Only show treatments of highest level
     * @param {string} [evidenceType] Evidence type.
     * @param {string} [hgvs] HGVS varaint. Its priority is higher than entrezGeneId/hugoSymbol + variant combination
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchGetUsingGET(
      id?: string,
      referenceGenome?: string,
      hugoSymbol?: string,
      entrezGeneId?: number,
      variant?: string,
      variantType?: string,
      svType?: 'DELETION' | 'TRANSLOCATION' | 'DUPLICATION' | 'INSERTION' | 'INVERSION' | 'FUSION' | 'UNKNOWN',
      consequence?: string,
      proteinStart?: number,
      proteinEnd?: number,
      tumorType?: string,
      levels?: string,
      highestLevelOnly?: boolean,
      evidenceType?: string,
      hgvs?: string,
      fields?: string,
      options?: any
    ): AxiosPromise<IndicatorQueryResp> {
      return localVarFp
        .searchGetUsingGET(
          id,
          referenceGenome,
          hugoSymbol,
          entrezGeneId,
          variant,
          variantType,
          svType,
          consequence,
          proteinStart,
          proteinEnd,
          tumorType,
          levels,
          highestLevelOnly,
          evidenceType,
          hgvs,
          fields,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     * General search for possible combinations.
     * @summary searchPost
     * @param {EvidenceQueries} body List of queries. Please see swagger.json for request body format.
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchPostUsingPOST(body: EvidenceQueries, fields?: string, options?: any): AxiosPromise<Array<IndicatorQueryResp>> {
      return localVarFp.searchPostUsingPOST(body, fields, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
  /**
   * General search for possible combinations.
   * @summary searchGet
   * @param {string} [id] The query ID
   * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
   * @param {string} [hugoSymbol] The gene symbol used in Human Genome Organisation.
   * @param {number} [entrezGeneId] The entrez gene ID.
   * @param {string} [variant] Variant name.
   * @param {string} [variantType] Variant type.
   * @param {'DELETION' | 'TRANSLOCATION' | 'DUPLICATION' | 'INSERTION' | 'INVERSION' | 'FUSION' | 'UNKNOWN'} [svType] Structural Variant Type.
   * @param {string} [consequence] Consequence
   * @param {number} [proteinStart] Protein Start
   * @param {number} [proteinEnd] Protein End
   * @param {string} [tumorType] Tumor type name. OncoTree code is supported.
   * @param {string} [levels] Level of evidences.
   * @param {boolean} [highestLevelOnly] Only show treatments of highest level
   * @param {string} [evidenceType] Evidence type.
   * @param {string} [hgvs] HGVS varaint. Its priority is higher than entrezGeneId/hugoSymbol + variant combination
   * @param {string} [fields] The fields to be returned.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public searchGetUsingGET(
    id?: string,
    referenceGenome?: string,
    hugoSymbol?: string,
    entrezGeneId?: number,
    variant?: string,
    variantType?: string,
    svType?: 'DELETION' | 'TRANSLOCATION' | 'DUPLICATION' | 'INSERTION' | 'INVERSION' | 'FUSION' | 'UNKNOWN',
    consequence?: string,
    proteinStart?: number,
    proteinEnd?: number,
    tumorType?: string,
    levels?: string,
    highestLevelOnly?: boolean,
    evidenceType?: string,
    hgvs?: string,
    fields?: string,
    options?: AxiosRequestConfig
  ) {
    return SearchApiFp(this.configuration)
      .searchGetUsingGET(
        id,
        referenceGenome,
        hugoSymbol,
        entrezGeneId,
        variant,
        variantType,
        svType,
        consequence,
        proteinStart,
        proteinEnd,
        tumorType,
        levels,
        highestLevelOnly,
        evidenceType,
        hgvs,
        fields,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * General search for possible combinations.
   * @summary searchPost
   * @param {EvidenceQueries} body List of queries. Please see swagger.json for request body format.
   * @param {string} [fields] The fields to be returned.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public searchPostUsingPOST(body: EvidenceQueries, fields?: string, options?: AxiosRequestConfig) {
    return SearchApiFp(this.configuration)
      .searchPostUsingPOST(body, fields, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * TrialsApi - axios parameter creator
 * @export
 */
export const TrialsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Return a list of trials using cancer types
     * @param {CancerTypesQuery} body body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trialsGetByCancerTypesUsingPOST: async (body: CancerTypesQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('trialsGetByCancerTypesUsingPOST', 'body', body);
      const localVarPath = `/trials/cancerTypes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Return a list of trials using OncoTree Code and/or treatment
     * @param {string} oncoTreeCode oncoTreeCode
     * @param {string} [treatment] treatment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trialsMatchingGetUsingGET: async (oncoTreeCode: string, treatment?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'oncoTreeCode' is not null or undefined
      assertParamExists('trialsMatchingGetUsingGET', 'oncoTreeCode', oncoTreeCode);
      const localVarPath = `/trials`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (oncoTreeCode !== undefined) {
        localVarQueryParameter['oncoTreeCode'] = oncoTreeCode;
      }

      if (treatment !== undefined) {
        localVarQueryParameter['treatment'] = treatment;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TrialsApi - functional programming interface
 * @export
 */
export const TrialsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TrialsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Return a list of trials using cancer types
     * @param {CancerTypesQuery} body body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async trialsGetByCancerTypesUsingPOST(
      body: CancerTypesQuery,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any }>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.trialsGetByCancerTypesUsingPOST(body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Return a list of trials using OncoTree Code and/or treatment
     * @param {string} oncoTreeCode oncoTreeCode
     * @param {string} [treatment] treatment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async trialsMatchingGetUsingGET(
      oncoTreeCode: string,
      treatment?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Trial>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.trialsMatchingGetUsingGET(oncoTreeCode, treatment, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * TrialsApi - factory interface
 * @export
 */
export const TrialsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = TrialsApiFp(configuration);
  return {
    /**
     *
     * @summary Return a list of trials using cancer types
     * @param {CancerTypesQuery} body body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trialsGetByCancerTypesUsingPOST(body: CancerTypesQuery, options?: any): AxiosPromise<{ [key: string]: any }> {
      return localVarFp.trialsGetByCancerTypesUsingPOST(body, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Return a list of trials using OncoTree Code and/or treatment
     * @param {string} oncoTreeCode oncoTreeCode
     * @param {string} [treatment] treatment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trialsMatchingGetUsingGET(oncoTreeCode: string, treatment?: string, options?: any): AxiosPromise<Array<Trial>> {
      return localVarFp.trialsMatchingGetUsingGET(oncoTreeCode, treatment, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * TrialsApi - object-oriented interface
 * @export
 * @class TrialsApi
 * @extends {BaseAPI}
 */
export class TrialsApi extends BaseAPI {
  /**
   *
   * @summary Return a list of trials using cancer types
   * @param {CancerTypesQuery} body body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrialsApi
   */
  public trialsGetByCancerTypesUsingPOST(body: CancerTypesQuery, options?: AxiosRequestConfig) {
    return TrialsApiFp(this.configuration)
      .trialsGetByCancerTypesUsingPOST(body, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Return a list of trials using OncoTree Code and/or treatment
   * @param {string} oncoTreeCode oncoTreeCode
   * @param {string} [treatment] treatment
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrialsApi
   */
  public trialsMatchingGetUsingGET(oncoTreeCode: string, treatment?: string, options?: AxiosRequestConfig) {
    return TrialsApiFp(this.configuration)
      .trialsMatchingGetUsingGET(oncoTreeCode, treatment, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * VariantsApi - axios parameter creator
 * @export
 */
export const VariantsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get All Actionable Variants.
     * @summary utilsAllActionableVariantsGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilsAllActionableVariantsGetUsingGET: async (version?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/utils/allActionableVariants`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get All Actionable Variants in text file.
     * @summary utilsAllActionableVariantsTxtGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilsAllActionableVariantsTxtGetUsingGET: async (version?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/utils/allActionableVariants.txt`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get All Annotated Variants.
     * @summary utilsAllAnnotatedVariantsGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilsAllAnnotatedVariantsGetUsingGET: async (version?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/utils/allAnnotatedVariants`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get All Annotated Variants in text file.
     * @summary utilsAllAnnotatedVariantsTxtGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilsAllAnnotatedVariantsTxtGetUsingGET: async (version?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/utils/allAnnotatedVariants.txt`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Search for matched variants.
     * @summary variantsLookupGet
     * @param {number} [entrezGeneId] The entrez gene ID. entrezGeneId is prioritize than hugoSymbol if both parameters have been defined
     * @param {string} [hugoSymbol] The gene symbol used in Human Genome Organisation.
     * @param {string} [variant] variant name.
     * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    variantsLookupGetUsingGET: async (
      entrezGeneId?: number,
      hugoSymbol?: string,
      variant?: string,
      referenceGenome?: string,
      fields?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/variants/lookup`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (entrezGeneId !== undefined) {
        localVarQueryParameter['entrezGeneId'] = entrezGeneId;
      }

      if (hugoSymbol !== undefined) {
        localVarQueryParameter['hugoSymbol'] = hugoSymbol;
      }

      if (variant !== undefined) {
        localVarQueryParameter['variant'] = variant;
      }

      if (referenceGenome !== undefined) {
        localVarQueryParameter['referenceGenome'] = referenceGenome;
      }

      if (fields !== undefined) {
        localVarQueryParameter['fields'] = fields;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Search for variants.
     * @summary variantsLookupPost
     * @param {Array<VariantSearchQuery>} body List of queries.
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    variantsLookupPostUsingPOST: async (
      body: Array<VariantSearchQuery>,
      fields?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('variantsLookupPostUsingPOST', 'body', body);
      const localVarPath = `/variants/lookup`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (fields !== undefined) {
        localVarQueryParameter['fields'] = fields;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * VariantsApi - functional programming interface
 * @export
 */
export const VariantsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = VariantsApiAxiosParamCreator(configuration);
  return {
    /**
     * Get All Actionable Variants.
     * @summary utilsAllActionableVariantsGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilsAllActionableVariantsGetUsingGET(
      version?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActionableGene>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.utilsAllActionableVariantsGetUsingGET(version, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get All Actionable Variants in text file.
     * @summary utilsAllActionableVariantsTxtGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilsAllActionableVariantsTxtGetUsingGET(
      version?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.utilsAllActionableVariantsTxtGetUsingGET(version, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get All Annotated Variants.
     * @summary utilsAllAnnotatedVariantsGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilsAllAnnotatedVariantsGetUsingGET(
      version?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnnotatedVariant>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.utilsAllAnnotatedVariantsGetUsingGET(version, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get All Annotated Variants in text file.
     * @summary utilsAllAnnotatedVariantsTxtGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilsAllAnnotatedVariantsTxtGetUsingGET(
      version?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.utilsAllAnnotatedVariantsTxtGetUsingGET(version, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Search for matched variants.
     * @summary variantsLookupGet
     * @param {number} [entrezGeneId] The entrez gene ID. entrezGeneId is prioritize than hugoSymbol if both parameters have been defined
     * @param {string} [hugoSymbol] The gene symbol used in Human Genome Organisation.
     * @param {string} [variant] variant name.
     * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async variantsLookupGetUsingGET(
      entrezGeneId?: number,
      hugoSymbol?: string,
      variant?: string,
      referenceGenome?: string,
      fields?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Alteration>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.variantsLookupGetUsingGET(
        entrezGeneId,
        hugoSymbol,
        variant,
        referenceGenome,
        fields,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Search for variants.
     * @summary variantsLookupPost
     * @param {Array<VariantSearchQuery>} body List of queries.
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async variantsLookupPostUsingPOST(
      body: Array<VariantSearchQuery>,
      fields?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Array<object>>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.variantsLookupPostUsingPOST(body, fields, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * VariantsApi - factory interface
 * @export
 */
export const VariantsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = VariantsApiFp(configuration);
  return {
    /**
     * Get All Actionable Variants.
     * @summary utilsAllActionableVariantsGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilsAllActionableVariantsGetUsingGET(version?: string, options?: any): AxiosPromise<Array<ActionableGene>> {
      return localVarFp.utilsAllActionableVariantsGetUsingGET(version, options).then(request => request(axios, basePath));
    },
    /**
     * Get All Actionable Variants in text file.
     * @summary utilsAllActionableVariantsTxtGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilsAllActionableVariantsTxtGetUsingGET(version?: string, options?: any): AxiosPromise<string> {
      return localVarFp.utilsAllActionableVariantsTxtGetUsingGET(version, options).then(request => request(axios, basePath));
    },
    /**
     * Get All Annotated Variants.
     * @summary utilsAllAnnotatedVariantsGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilsAllAnnotatedVariantsGetUsingGET(version?: string, options?: any): AxiosPromise<Array<AnnotatedVariant>> {
      return localVarFp.utilsAllAnnotatedVariantsGetUsingGET(version, options).then(request => request(axios, basePath));
    },
    /**
     * Get All Annotated Variants in text file.
     * @summary utilsAllAnnotatedVariantsTxtGet
     * @param {string} [version] The data version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilsAllAnnotatedVariantsTxtGetUsingGET(version?: string, options?: any): AxiosPromise<string> {
      return localVarFp.utilsAllAnnotatedVariantsTxtGetUsingGET(version, options).then(request => request(axios, basePath));
    },
    /**
     * Search for matched variants.
     * @summary variantsLookupGet
     * @param {number} [entrezGeneId] The entrez gene ID. entrezGeneId is prioritize than hugoSymbol if both parameters have been defined
     * @param {string} [hugoSymbol] The gene symbol used in Human Genome Organisation.
     * @param {string} [variant] variant name.
     * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    variantsLookupGetUsingGET(
      entrezGeneId?: number,
      hugoSymbol?: string,
      variant?: string,
      referenceGenome?: string,
      fields?: string,
      options?: any
    ): AxiosPromise<Array<Alteration>> {
      return localVarFp
        .variantsLookupGetUsingGET(entrezGeneId, hugoSymbol, variant, referenceGenome, fields, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Search for variants.
     * @summary variantsLookupPost
     * @param {Array<VariantSearchQuery>} body List of queries.
     * @param {string} [fields] The fields to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    variantsLookupPostUsingPOST(body: Array<VariantSearchQuery>, fields?: string, options?: any): AxiosPromise<Array<Array<object>>> {
      return localVarFp.variantsLookupPostUsingPOST(body, fields, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * VariantsApi - object-oriented interface
 * @export
 * @class VariantsApi
 * @extends {BaseAPI}
 */
export class VariantsApi extends BaseAPI {
  /**
   * Get All Actionable Variants.
   * @summary utilsAllActionableVariantsGet
   * @param {string} [version] The data version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VariantsApi
   */
  public utilsAllActionableVariantsGetUsingGET(version?: string, options?: AxiosRequestConfig) {
    return VariantsApiFp(this.configuration)
      .utilsAllActionableVariantsGetUsingGET(version, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get All Actionable Variants in text file.
   * @summary utilsAllActionableVariantsTxtGet
   * @param {string} [version] The data version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VariantsApi
   */
  public utilsAllActionableVariantsTxtGetUsingGET(version?: string, options?: AxiosRequestConfig) {
    return VariantsApiFp(this.configuration)
      .utilsAllActionableVariantsTxtGetUsingGET(version, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get All Annotated Variants.
   * @summary utilsAllAnnotatedVariantsGet
   * @param {string} [version] The data version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VariantsApi
   */
  public utilsAllAnnotatedVariantsGetUsingGET(version?: string, options?: AxiosRequestConfig) {
    return VariantsApiFp(this.configuration)
      .utilsAllAnnotatedVariantsGetUsingGET(version, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get All Annotated Variants in text file.
   * @summary utilsAllAnnotatedVariantsTxtGet
   * @param {string} [version] The data version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VariantsApi
   */
  public utilsAllAnnotatedVariantsTxtGetUsingGET(version?: string, options?: AxiosRequestConfig) {
    return VariantsApiFp(this.configuration)
      .utilsAllAnnotatedVariantsTxtGetUsingGET(version, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Search for matched variants.
   * @summary variantsLookupGet
   * @param {number} [entrezGeneId] The entrez gene ID. entrezGeneId is prioritize than hugoSymbol if both parameters have been defined
   * @param {string} [hugoSymbol] The gene symbol used in Human Genome Organisation.
   * @param {string} [variant] variant name.
   * @param {string} [referenceGenome] Reference genome, either GRCh37 or GRCh38. The default is GRCh37
   * @param {string} [fields] The fields to be returned.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VariantsApi
   */
  public variantsLookupGetUsingGET(
    entrezGeneId?: number,
    hugoSymbol?: string,
    variant?: string,
    referenceGenome?: string,
    fields?: string,
    options?: AxiosRequestConfig
  ) {
    return VariantsApiFp(this.configuration)
      .variantsLookupGetUsingGET(entrezGeneId, hugoSymbol, variant, referenceGenome, fields, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Search for variants.
   * @summary variantsLookupPost
   * @param {Array<VariantSearchQuery>} body List of queries.
   * @param {string} [fields] The fields to be returned.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VariantsApi
   */
  public variantsLookupPostUsingPOST(body: Array<VariantSearchQuery>, fields?: string, options?: AxiosRequestConfig) {
    return VariantsApiFp(this.configuration)
      .variantsLookupPostUsingPOST(body, fields, options)
      .then(request => request(this.axios, this.basePath));
  }
}
